import React, { useState, useEffect } from 'react';
import { QuestionCircleOutlined, MailFilled } from '@ant-design/icons';
import { FloatButton, Input, Button, Flex, Progress } from 'antd';
import cn from 'classnames';
import { validateEmailAddress } from '../../utils/validateEmailAdress';
import { useAskAQuestionMutation } from 'src/redux/queries/mailApi';

import './AskAQuestion.scss';
import { useGetUserInfo } from 'src/hooks/useGetUser';

const { TextArea } = Input;

function AskAQuestion() {
  const { user } = useGetUserInfo();
  const [open, setOpen] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [messageSuccess, setMessageSuccess] = useState<boolean>(false);

  const [askAQuestion, { isLoading }] = useAskAQuestionMutation();

  useEffect(() => {
    if (user.emailAddress) {
      setEmailAddress(user?.emailAddress as string);
    }
  }, [user.emailAddress]);

  const handleCancel = () => {
    setOpen(false);
    setMessageSuccess(false);
    setShowError(false);
    setMessage('');
    setEmailAddress(user.emailAddress || '');
  };

  const handleOpenChange = () => {
    setOpen(true);
  };

  const handleSendMail = async () => {
    setShowError(true);

    try {
      const result = await askAQuestion({ emailAddress, message }).unwrap();
      setMessageSuccess(result.success);
      if (result.success)
        setTimeout(() => {
          handleCancel();
        }, 1000);
    } catch (e) {
      setMessageSuccess(false);
      console.error(e);
    }
  };

  const validMessage = validateEmailAddress(emailAddress) && message && emailAddress.length > 0 && message.length > 2;

  return (
    <div style={{ position: 'relative' }}>
      <div className={cn('ask-a-question__mask', { 'ask-a-question__mask--open': open })} />
      <div className={cn('ask-a-question__container', { 'ask-a-question__container--open': open })}>
        {messageSuccess ? (
          <Flex align="center" gap={16} vertical={true} justify="center" style={{ height: '100%' }}>
            <Progress type="circle" percent={100} size={80} />
            <div>Thank you!</div>
          </Flex>
        ) : (
          <>
            <div className="ask-a-question__input">
              <Input
                size="large"
                placeholder="Email address"
                variant="filled"
                value={emailAddress}
                disabled={!!user?.emailAddress}
                status={showError && emailAddress && !validateEmailAddress(emailAddress) ? 'error' : undefined}
                onChange={e => setEmailAddress(e.target.value)}
              />
            </div>
            <div className="ask-a-question__input">
              <TextArea
                size="large"
                placeholder="Ask a question?"
                rows={4}
                variant="filled"
                status={showError && !message ? 'error' : undefined}
                onChange={e => setMessage(e.target.value)}
              />
            </div>
            <Flex align="center" gap={8} justify="flex-end">
              <Button type="text" size="large" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                icon={<MailFilled />}
                type="primary"
                size="large"
                disabled={!validMessage}
                onClick={handleSendMail}
                loading={isLoading}
              >
                Submit
              </Button>
            </Flex>
          </>
        )}
      </div>
      <FloatButton
        onClick={handleOpenChange}
        icon={<QuestionCircleOutlined />}
        type="primary"
        style={{ bottom: 16, right: 16 }}
      />
    </div>
  );
}

export default AskAQuestion;
