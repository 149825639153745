import React from 'react';
import { Avatar, Flex, Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { AreaChart, XAxis, YAxis, ReferenceLine, Tooltip, ResponsiveContainer, CartesianGrid, Area } from 'recharts';

import './PlayerStats.scss';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { useGetUserInfo } from '../../hooks/useGetUser';

export type PlayerStatsPropsData = {
  chartLabel: string;
  percentage: number;
  xAxisKey: string;
  dataKey: string;
  referenceLine: number;
  chartData: Record<string, any>[];
};

type PlayerStatsProps = {
  isLoading: boolean;
  data: PlayerStatsPropsData[];
};

function round(num: number, decimalPlaces = 0) {
  const p = Math.pow(10, decimalPlaces);
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}

const PlayerStats = ({ data, isLoading }: PlayerStatsProps) => {
  const navigate = useNavigate();
  const {
    user: { basicPlan, proPlan },
  } = useGetUserInfo();

  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const percentageContainerCss = cn('player-stats__percentage-container', {
    'player-stats__percentage-container--mobile': isTabletOrMobile,
  });

  const renderChart = (dataSet: PlayerStatsPropsData, isTabletOrMobile: boolean) => {
    const over50Percent = dataSet.percentage > 50;

    const chartContainerCss = cn('player-stats__chart-container', {
      'player-stats__chart-container--mobile': isTabletOrMobile,
    });

    return (
      <div className={chartContainerCss}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={[...dataSet.chartData].reverse()}
            margin={{
              top: 20,
              right: isTabletOrMobile ? 0 : 30,
              left: isTabletOrMobile ? -20 : 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUnder" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#e80505" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#a30303" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorOver" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#00de29" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="1 1" opacity={0.25} />
            <XAxis dataKey={dataSet.xAxisKey} />
            <YAxis />
            <Tooltip />

            <Area
              type="monotone"
              fillOpacity={1}
              dataKey={dataSet.dataKey}
              stroke={over50Percent ? '#82ca9d' : '#e80505'}
              opacity={1}
              fill={over50Percent ? 'url(#colorOver)' : 'url(#colorUnder)'}
              activeDot={{ r: 8 }}
              dot={{ r: 4 }}
            />
            <ReferenceLine
              y={dataSet.referenceLine}
              label={`Over/Under ${dataSet.referenceLine}`}
              stroke="#ff0000"
              strokeDasharray="3 3"
              style={{ strokeWidth: 2 }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const SkeletonChart = ({ isMobile = false }) => {
    const skeletonCss = cn('player-stats__skeleton-chart', {
      'player-stats__skeleton-chart--mobile': isMobile,
    });
    return !basicPlan && !proPlan ? (
      <div>
        <Flex align="center" style={{ width: '100%', marginTop: 16 }}>
          {!isMobile && (
            <div className={percentageContainerCss}>
              <div>
                <Avatar size={150} icon={<LockOutlined />} style={{ opacity: '0.05' }} />
              </div>
            </div>
          )}

          <div className={skeletonCss}>
            <div>Subscribe to view home/away/opponent stats</div>
            <div>
              <Button onClick={() => navigate('/pricing')} type="primary" size="large">
                Subscribe
              </Button>
            </div>
          </div>
        </Flex>
      </div>
    ) : null;
  };

  if (isTabletOrMobile) {
    return (
      <div className="player-stats__container player-stats__container--mobile">
        {isLoading && (
          <div className="player-stats__loading">
            <div className="player-stats__loading-spinner">
              <Spin size="large" tip="Loading data" />
            </div>
          </div>
        )}
        {data.map(dataSet => {
          const percentageCss = cn('player-stats__percentage', {
            'player-stats__percentage--mobile': isTabletOrMobile,
            'player-stats__percentage--positive': dataSet.percentage > 50,
            'player-stats__percentage--negative': dataSet.percentage < 50,
          });

          return (
            <div>
              <div className={percentageContainerCss}>
                <div className={percentageCss}>
                  <div className="player-stats__percentage-label">HIT RATE</div>
                  <div className="player-stats__percentage-metric">
                    {round(dataSet.percentage, 1)}
                    <span>%</span>
                  </div>
                  <div className="player-stats__percentage-label">{dataSet.chartLabel}</div>
                </div>
              </div>
              {renderChart(dataSet, isTabletOrMobile)}
            </div>
          );
        })}
        <SkeletonChart isMobile={isTabletOrMobile} />
      </div>
    );
  }

  return (
    <div className="player-stats__container">
      {isLoading && (
        <div className="player-stats__loading">
          <div className="player-stats__loading-spinner">
            <Spin size="large" tip="Loading data" />
          </div>
        </div>
      )}
      {data.map(dataSet => {
        const over50Percent = dataSet.percentage > 50;
        const under50Percent = dataSet.percentage < 50;

        const percentageCss = cn('player-stats__percentage', {
          'player-stats__percentage--positive': over50Percent,
          'player-stats__percentage--negative': under50Percent,
        });

        return (
          <Flex align="center" style={{ width: '100%', height: '200px' }} key={`${dataSet.chartLabel}`}>
            <div className={percentageContainerCss}>
              <div className={percentageCss}>
                <div className="player-stats__percentage-label">HIT RATE</div>
                <div className="player-stats__percentage-metric">
                  {round(dataSet.percentage, 1)}
                  <span>%</span>
                </div>
                <div className="player-stats__percentage-label">{dataSet.chartLabel}</div>
              </div>
            </div>

            {renderChart(dataSet, isTabletOrMobile)}
          </Flex>
        );
      })}
      <SkeletonChart />
    </div>
  );
};

export default PlayerStats;
