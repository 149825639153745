import { baseApi } from './baseApi';
import {
  NbaStatsBody,
  MlbTeams,
  MlbTeam,
  MlbPlayers,
  MlbPlayer,
  MlbPlayerInfo,
  MlbPlayerStatResult,
} from '../../types';

export const mlbApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getMlbTeams: build.query<MlbTeam[], void>({
      query: () => `/mlb/teams`,
      transformResponse: async (baseQueryReturnValue: Promise<MlbTeams>) => {
        const teams = await baseQueryReturnValue;
        return teams.body;
      },
    }),
    getMlbPlayers: build.query<MlbPlayer[], void>({
      query: () => `/mlb/players`,
      transformResponse: async (baseQueryReturnValue: Promise<MlbPlayers>) => {
        const players = await baseQueryReturnValue;
        return players.body.filter(player => !!player.team);
      },
    }),
    getMlbPlayerInfo: build.query<MlbPlayerInfo, { playerId: string }>({
      query: ({ playerId }) => `/mlb/players/${playerId}`,
    }),
    getMlbPlayerStats: build.query<MlbPlayerStatResult, { playerId: string; body: NbaStatsBody }>({
      query: ({ playerId, body }) => ({
        url: `/mlb/players/${playerId}/stats`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const { useGetMlbPlayerStatsQuery, useGetMlbTeamsQuery, useGetMlbPlayersQuery, useGetMlbPlayerInfoQuery } =
  mlbApi;
