import React, { useState } from 'react';
import { Flex, Select, DatePicker, Button, Divider } from 'antd';
import dayjs from 'dayjs';
import { useGetTradesQuery, useCreateTradesMutation, useDeleteTradesMutation } from '../../redux/queries/adminApi';
import { Sport } from '../../types';
import { useGetNbaPlayersQuery } from '../../redux/queries/nbaApi';
import { useGetNflPlayersQuery } from '../../redux/queries/nflApi';
import { useGetMlbPlayersQuery } from '../../redux/queries/mlbApi';
import { useGetNhlPlayersQuery } from '../../redux/queries/nhlApi';

import PlayerListing from '../PlayerSelection/PlayerListing';
import SelectTeams from '../PlayerSelection/SelectTeams';

function Trades() {
  const [sport, setSport] = useState<Sport>(Sport.nfl);
  const { data: trades } = useGetTradesQuery({ sport });

  const [toTeam, setToTeam] = useState<string | null>(null);
  const [playerIdToAdd, handleSetPlayerId] = useState<string | null>(null);
  const [dateEnd, setDateEnd] = useState<string | null>(null);

  const { data: nbaPlayers } = useGetNbaPlayersQuery(undefined, {
    skip: sport !== Sport.nba,
  });
  const { data: nflPlayers } = useGetNflPlayersQuery(undefined, {
    skip: sport !== Sport.nfl,
  });
  const { data: mlbPlayers } = useGetMlbPlayersQuery(undefined, {
    skip: sport !== Sport.mlb,
  });
  const { data: nhlPlayers } = useGetNhlPlayersQuery(undefined, {
    skip: sport !== Sport.nhl,
  });

  const [deleteTrades] = useDeleteTradesMutation();
  const [createTrades] = useCreateTradesMutation();

  const players =
    sport === Sport.nba ? nbaPlayers : sport === Sport.mlb ? mlbPlayers : sport === Sport.nhl ? nhlPlayers : nflPlayers;

  const handleCreateTrade = async () => {
    if (!toTeam || !playerIdToAdd || !dateEnd) {
      return;
    }
    await createTrades({ sport, playerId: playerIdToAdd, team: toTeam, end: dateEnd });

    setToTeam(null);
    handleSetPlayerId(null);
    setDateEnd(null);
  };

  const handleDeleteTrade = async (id: string) => {
    await deleteTrades({ sport, id });
  };

  return (
    <div>
      <Select
        options={[
          { value: Sport.nfl, label: 'NFL' },
          { value: Sport.mlb, label: 'MLB' },
          { value: Sport.nhl, label: 'NHL' },
          { value: Sport.nba, label: 'NBA' },
        ]}
        value={sport}
        onChange={setSport}
      />
      <Flex align="center" gap={16} style={{ marginBottom: 16 }}>
        <div>Add Trade</div>
        <div>
          <PlayerListing sport={sport} team={null} playerId={playerIdToAdd} onChange={handleSetPlayerId} />
        </div>
        <div>
          from team:
          <SelectTeams sport={sport} team={toTeam} onChange={setToTeam} />
        </div>
        <div>
          end date: <DatePicker variant="filled" size="middle" value={dateEnd} onChange={setDateEnd} />
        </div>
        <div>
          <Button type="primary" onClick={handleCreateTrade}>
            Add
          </Button>
        </div>
      </Flex>
      <Divider />
      <div>
        {trades &&
          trades.map(trade => {
            const player = players?.find(p => p.playerID === trade.playerId);
            return (
              <Flex align="center" gap={16} style={{ marginBottom: 16 }}>
                <div>
                  {trade.playerId} {player?.longName}
                </div>
                <div>{trade.team}</div>
                <div>{dayjs(trade.end).format('MM/DD/YYYY')}</div>
                <div>
                  <Button type="primary" danger onClick={() => handleDeleteTrade(trade.id)}>
                    Remove
                  </Button>
                </div>
              </Flex>
            );
          })}
      </div>
    </div>
  );
}

export default Trades;
