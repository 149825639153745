import React from 'react';
import { MlbTeam, NbaTeam, NflTeam, NhlTeam, Sport } from '../../types/index';
import { Select } from 'antd';
import { useGetNbaTeamsQuery } from '../../redux/queries/nbaApi';
import { useGetNflTeamsQuery } from '../../redux/queries/nflApi';
import { useGetMlbTeamsQuery } from '../../redux/queries/mlbApi';
import { useGetNhlTeamsQuery } from '../../redux/queries/nhlApi';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

const formatOptions = (data: NflTeam[] | NbaTeam[] | MlbTeam[] | NhlTeam[] | undefined) => {
  if (!data) return [];
  return (data || [])
    .map(team => ({
      value: team.teamAbv,
      label: `${team.teamCity} ${team.teamName} (${team.teamAbv})`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

const SelectTeams = ({
  sport,
  team,
  onChange,
}: {
  sport: Sport;
  team: string | null;
  onChange: (value: string) => void;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const { data: nbaTeams } = useGetNbaTeamsQuery(undefined, { skip: sport !== Sport.nba });
  const { data: nflTeams } = useGetNflTeamsQuery(undefined, { skip: sport !== Sport.nfl });
  const { data: mlbTeams } = useGetMlbTeamsQuery(undefined, { skip: sport !== Sport.mlb });
  const { data: nhlTeams } = useGetNhlTeamsQuery(undefined, { skip: sport !== Sport.nhl });

  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      variant="filled"
      allowClear
      showSearch
      filterOption={filterOption}
      placeholder="All Teams"
      options={
        sport === Sport.nba
          ? formatOptions(nbaTeams)
          : sport === Sport.mlb
            ? formatOptions(mlbTeams)
            : sport === Sport.nhl
              ? formatOptions(nhlTeams)
              : formatOptions(nflTeams)
      }
      onChange={onChange}
      value={team}
      size={isTabletOrMobile ? 'large' : 'middle'}
      style={{ width: isTabletOrMobile ? '100%' : 200 }}
    />
  );
};

export default SelectTeams;
