import { ClerkProvider } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';

const PUBLISHABLE_KEY: string = process.env.REACT_APP_PUBLISHABLE_KEY || '';

const ClerkProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ClerkProvider
      signInUrl={'/signin'}
      signUpUrl={'/signup'}
      signInForceRedirectUrl={'/sports/nfl'}
      signUpForceRedirectUrl={'/sports/nfl'} // TODO: add query to show thank you message
      publishableKey={PUBLISHABLE_KEY}
      appearance={{
        baseTheme: dark,
        variables: {
          colorBackground: '#202020',
          colorPrimary: '#f55e00',
          fontSize: '16px',
        },
      }}
    >
      {children}
    </ClerkProvider>
  );
};

export { ClerkProviderWrapper };
