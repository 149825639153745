import { baseApi } from './baseApi';
import { Sport } from '../../types';

export const adminApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getTrades: build.query<any[], { sport: string }>({
      query: ({ sport }) => `/admin/${sport}/trades`,
      providesTags: ['Trades'],
    }),
    deleteTrades: build.mutation<any[], { sport: string; id: string }>({
      query: ({ sport, id }) => {
        return {
          url: `/admin/${sport}/trades/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Trades'],
    }),
    createTrades: build.mutation<any[], { sport: string; playerId: string; team: string; end: string }>({
      query: ({ sport, playerId, team, end }) => {
        return {
          url: `/admin/${sport}/trades`,
          method: 'POST',
          body: { sport, playerId, team, end },
        };
      },
      invalidatesTags: ['Trades'],
    }),
    getPicks: build.mutation<undefined, { sport: Sport }>({
      query: ({ sport }) => {
        return {
          url: `/admin/${sport}/picks`,
          method: 'POST',
          body: { sport },
        };
      },
      invalidatesTags: ['Picks'],
    }),
  }),
});

export const { useGetTradesQuery, useCreateTradesMutation, useDeleteTradesMutation, useGetPicksMutation } = adminApi;
