import React from 'react';
import { Flex } from 'antd';
import { Routes, Route, NavLink } from 'react-router-dom';
import Trades from './Trades';
import Picks from './Picks';

function Admin() {
  return (
    <Flex>
      <div>
        <ul>
          <li>
            <NavLink to="/admin/trades">Trades</NavLink>
          </li>
          <li>
            <NavLink to="/admin/picks">Picks</NavLink>
          </li>
        </ul>
      </div>
      <div style={{ margin: 16 }}>
        <Routes>
          <Route path="/trades" element={<Trades />} />
          <Route path="/picks" element={<Picks />} />
        </Routes>
      </div>
    </Flex>
  );
}

export default Admin;
