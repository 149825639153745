import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NbaStatsBody } from '../../types';
import { RootState } from 'src/redux/store';
import { updateNoOfGames } from 'src/redux/slices/researchSlice';
import NoOfGamesDumb from './NoOfGames_Dumb';

export default function NoOfGames() {
  const dispatch = useDispatch();
  const requestBody: NbaStatsBody = useSelector((state: RootState) => state.research.body);

  const handleSetNoOfGames = (noOfGames: number) => {
    dispatch(updateNoOfGames({ noOfGames }));
  };

  return <NoOfGamesDumb noOfGames={requestBody.noOfGames} handleSetNoOfGames={handleSetNoOfGames} />;
}
