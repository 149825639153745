export function priceAdjustedRating({ rating, price }: { rating: number; price: number }): number {
  return price >= 150
    ? rating * 1.3
    : price >= 110
      ? rating * 1.1
      : price <= -500
        ? rating * 0.75
        : price <= -200
          ? rating * 0.9
          : rating;
}
