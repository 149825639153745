import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TabsSliceState {
  activeTab: string | undefined;
}

const initialState: TabsSliceState = {
  activeTab: undefined,
};

export const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab: (state, { payload }: PayloadAction<{ activeTab: string }>) => {
      state.activeTab = payload.activeTab;
    },
  },
});

export const { setActiveTab } = tabsSlice.actions;

export default tabsSlice.reducer;
