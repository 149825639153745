import { onCLS, onFCP, onLCP, onTTFB } from 'web-vitals';

const reportWebVitals = (onPerfEntry: any) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    //import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    onCLS(onPerfEntry);
    // getFID(onPerfEntry);
    onFCP(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
    //});
  }
};

export default reportWebVitals;
