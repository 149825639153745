import { AskAQuestionResult, AskAQuestionBody } from '../../types';
import { baseApi } from './baseApi';

export const mailApi = baseApi.injectEndpoints({
  endpoints: build => ({
    askAQuestion: build.mutation<AskAQuestionResult, AskAQuestionBody>({
      query: ({ message, emailAddress }: { message: string; emailAddress: string }) => ({
        url: '/ask-a-question',
        method: 'POST',
        body: { message, emailAddress },
      }),
    }),
  }),
});

export const { useAskAQuestionMutation } = mailApi;
