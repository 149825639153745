import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { PicksRecord, GroupedPick } from './OurPicks';
import { Flex, Skeleton, Button, message } from 'antd';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { Rating } from '../PriceRating';
import { useGetSportsMarketsQuery } from '../../../redux/queries/bettingApi';
import { useCreateFavoriteMutation, useGetFavoritesQuery } from 'src/redux/queries/favoritesApi';
import './OurPicks.scss';
import PlayerHeader from '../PlayerHeader';
import MarketTableStats from '../MarketTableStats';
import MarketHeader from '../MarketHeader';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../../constants';
import BookmakerDisplay from '../BookmakerDisplay';
import OverUnderDisplay from '../OverUnderDisplay';
import { Favorite } from 'src/types';

enum FavoriteType {
  BETTING = 'betting',
  RESEARCH = 'research',
}

function Pick({ pick, condensed }: { pick: GroupedPick; condensed: boolean }) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const { data: markets } = useGetSportsMarketsQuery({ sport: pick.requestBody.sport });
  const { data: favorites = [] } = useGetFavoritesQuery();
  const [createFavorite, { isLoading: isCreatingFavorite }] = useCreateFavoriteMutation();

  const market = markets?.find(m => m.key === pick.marketName);
  const playerTeam = pick.player?.body.team === pick.homeTeam?.teamAbv ? pick.homeTeam : pick.awayTeam;
  const opponentTeam = pick.player?.body.team !== pick.homeTeam?.teamAbv ? pick.homeTeam : pick.awayTeam;

  const TeamName = ({ teamAbv }: { teamAbv: string }) => {
    if (playerTeam?.teamAbv === teamAbv) {
      return <strong>{teamAbv}</strong>;
    }

    return <>{teamAbv}</>;
  };

  const handleCreateFavorite = useCallback(async () => {
    const favorite = {
      type: FavoriteType.BETTING,
      requestBody: pick.requestBody,
      picks: pick.picks,
    };
    try {
      await createFavorite(favorite);
      message.success('Added to favorites');
    } catch (e) {
      message.error('Failed to add to favorites');
    }
  }, [createFavorite, pick]);

  const cardCss = cn('pick__card', {
    'pick__card--mobile': isTabletOrMobile,
  });

  const isFavorite = useMemo(() => {
    if (!favorites || !pick) return false;

    const found = favorites.filter((f: Favorite) => {
      return (
        f.requestBody.playerId === pick.playerId &&
        f.requestBody.overUnder === pick.requestBody.overUnder &&
        f.requestBody.threshold === pick.requestBody.threshold &&
        JSON.stringify(f.requestBody.metrics) === JSON.stringify(pick.requestBody.metrics)
      );
    });
    return found.length > 0;
  }, [pick, favorites]);

  if (!markets) return <Skeleton />;

  return (
    <div className={cardCss}>
      <div className="pick__card-favorites">
        {isFavorite ? (
          <div style={{ padding: 8 }}>
            <HeartFilled style={{ color: 'red' }} />
          </div>
        ) : (
          <Button
            icon={isFavorite ? <HeartFilled /> : <HeartOutlined />}
            type="text"
            shape="circle"
            danger
            onClick={handleCreateFavorite}
            loading={isCreatingFavorite}
          />
        )}
      </div>
      <PlayerHeader
        loading={false}
        playerName={pick.player?.body.longName as string}
        headshot={pick.player?.body.espnHeadshot || (pick.player?.body.mlbHeadshot as string)}
        teamName={playerTeam?.teamName as string}
        teamCity={playerTeam?.teamCity as string}
        teamLogo={playerTeam?.espnLogo1 as string}
        position={pick.player?.body.pos as string}
        jerseyNum={pick.player?.body.jerseyNum as string}
        showTeam={false}
        gameDetails={
          <div className="pick__game-details">
            <div>
              <TeamName teamAbv={pick.homeTeam?.teamAbv as string} /> vs.{' '}
              <TeamName teamAbv={pick.awayTeam?.teamAbv as string} />{' '}
              <span className="pick__game-time">at {dayjs(pick.commence_time).format('MM/DD, hh:mm A')}</span>
            </div>
          </div>
        }
      />
      <MarketHeader
        label={market?.label as string}
        playerId={pick.playerId as string}
        team={playerTeam?.teamAbv as string}
        opponent={opponentTeam?.teamAbv as string}
        sport={pick.sport}
        threshold={pick.requestBody.threshold ?? 0}
        metrics={market?.statMetric || []}
        overUnder={pick.requestBody.overUnder}
        noOfGames={12}
        compact={true}
      >
        <OverUnderDisplay
          overUnder={pick.requestBody.overUnder}
          threshold={pick.requestBody.threshold}
          compact={true}
        />
      </MarketHeader>
      {!condensed && (
        <MarketTableStats body={pick.requestBody} stats={pick.stats?.stats} compact={true} showOverUnder={false} />
      )}

      <Flex align="center" gap={8} style={{ width: '100%' }} className="pick__betting-line">
        {pick.picks.map((pick: PicksRecord) => (
          <BookmakerDisplay
            key={`${pick.bookmaker}-${pick.price}`}
            bookmaker={pick.bookmaker}
            price={pick.price}
            compact={true}
          >
            <Rating rating={pick.rating} />
          </BookmakerDisplay>
        ))}
      </Flex>
    </div>
  );
}

export default Pick;
