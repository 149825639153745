import { ConfigProvider, theme } from 'antd';

const AntDProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#f55e00',
        },
        components: {
          Tabs: {
            horizontalMargin: '0 12px',
            horizontalItemPadding: '12px 0',
          },
          Drawer: {},
        },
        // 1. Use dark algorithm
        algorithm: theme.darkAlgorithm,

        // 2. Combine dark algorithm and compact algorithm
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm]
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export { AntDProvider };
