import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Select } from 'antd';
import { MIN_NO_OF_GAMES, MAX_NO_OF_GAMES } from '../../constants';
import { useGetUserInfo } from '../../hooks/useGetUser';

export default function NoOfGames_Dumb({
  noOfGames,
  handleSetNoOfGames,
}: {
  noOfGames: number;
  handleSetNoOfGames: (noOfGames: number) => void;
}) {
  const {
    user: { basicPlan, proPlan },
  } = useGetUserInfo();

  return (
    <Flex justify="center" gap={8} align="center" style={{ padding: '8px 0' }} vertical={true}>
      <Select
        defaultValue={noOfGames}
        value={noOfGames}
        style={{ width: 175 }}
        onChange={handleSetNoOfGames}
        options={Array(basicPlan || proPlan ? MAX_NO_OF_GAMES : MIN_NO_OF_GAMES)
          .fill({})
          .map((_, index) => {
            return {
              value: index + 1,
              label: `last ${index + 1} games`,
            };
          })}
      />
      {!(basicPlan || proPlan) && (
        <p style={{ marginTop: 8 }}>
          Want more history? <Link to="/pricing">Cick here.</Link>
        </p>
      )}
    </Flex>
  );
}
