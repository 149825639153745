import React from 'react';
import { Flex } from 'antd';
import { useGetMlbPlayerInfoQuery, useGetMlbPlayerStatsQuery, useGetMlbTeamsQuery } from '../../redux/queries/mlbApi';
import { NbaStatTypes, NbaStatsBody, NflStatTypes, Sport } from '../../types';
import PlayerInfo from './PlayerInfo';
import PlayerStats from './PlayerStats';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import cn from 'classnames';

import './Player.scss';
import StatsTable from './StatsTable';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import NoOfGames from './NoOfGames';

const MlbPlayer = ({ playerId, statsBody }: { playerId: string | null; statsBody: NbaStatsBody }) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const requestBody: NbaStatsBody = useSelector((state: RootState) => state.research.body);

  const { data: playerInfo, isLoading: playerInfoLoading } = useGetMlbPlayerInfoQuery(
    { playerId: playerId as string },
    { skip: !playerId }
  );

  const { data: playerStats, isFetching: playerStatsLoading } = useGetMlbPlayerStatsQuery(
    {
      playerId: playerId as string,
      body: {
        ...statsBody,
        noOfGames: requestBody.noOfGames,
      },
    },
    { skip: !playerId }
  );

  const { data: teams, isLoading: teamsLoading } = useGetMlbTeamsQuery();
  const playerTeam = teams?.find(team => team.teamAbv === playerInfo?.body.team);

  const dataKeys: NbaStatTypes[] = playerStats ? (Object.keys(playerStats?.stats) as NflStatTypes[]) : [];
  const chartData = playerStats?.stats
    ? dataKeys.map(key => {
        return {
          chartLabel: key === 'vs' ? `${key} ${requestBody.vsOpponent}` : key === 'all' ? 'Overall' : `${key}`,
          percentage: playerStats?.stats[key].percentage,
          xAxisKey: 'game',
          dataKey: 'total',
          referenceLine: playerStats.criteria.threshold,
          chartData: playerStats.stats[key].stats,
        };
      })
    : [];

  const playerCss = cn('player__container', {
    'player__container--mobile': isTabletOrMobile,
  });

  return (
    <div className={playerCss}>
      <Flex gap={16} align="flex-start" vertical={isTabletOrMobile}>
        {playerInfo && playerTeam && (
          <PlayerInfo
            isLoading={playerInfoLoading || teamsLoading}
            headShot={playerInfo?.body.espnHeadshot}
            teamLogo={playerTeam.espnLogo1}
            displayName={`${playerInfo.body.longName} (${playerInfo.body.pos})`}
          >
            <NoOfGames />
          </PlayerInfo>
        )}
        <div style={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>
          <PlayerStats data={chartData} isLoading={playerStatsLoading} />
          <StatsTable
            data={chartData}
            isLoading={playerStatsLoading}
            sport={Sport.mlb}
            position={playerInfo?.body.pos}
          />
        </div>
      </Flex>
    </div>
  );
};

export default MlbPlayer;
