import React from 'react';
import { Flex, Button, message } from 'antd';
import cn from 'classnames';
import { DeleteFilled, EyeFilled } from '@ant-design/icons';
import { MlbStatsMetric, NbaStatsMetric, NflStatsMetric, NhlStatsMetric, Sport, Favorite } from '../../types';
import { useGetNbaPlayerInfoQuery } from '../../redux/queries/nbaApi';
import { useGetNflPlayerInfoQuery } from '../../redux/queries/nflApi';
import { useGetMlbPlayerInfoQuery } from '../../redux/queries/mlbApi';
import { useGetNhlPlayerInfoQuery } from '../../redux/queries/nhlApi';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { sportMetrics, MetricGroup, MetricOption } from '../PlayerSelection/SelectMetric';
import { useDispatch } from 'react-redux';
import { setBody } from 'src/redux/slices/researchSlice';
import { useNavigate } from 'react-router-dom';
import { useDeleteFavoriteMutation } from 'src/redux/queries/favoritesApi';
import FavoritePlayerHeader from './FavoritePlayerHeader';
import FavoritePlayerStats from './FavoritePlayerStats';
import MarketHeader from '../Betting/MarketHeader';
import OverUnderDisplay from '../Betting/OverUnderDisplay';
import BookmakerDisplay from '../Betting/BookmakerDisplay';
import { PicksRecord } from '../Betting/OurPicks/OurPicks';
import { Rating } from '../Betting/PriceRating';

const getMetricLabel = (
  sport: Sport,
  metrics: NbaStatsMetric[] | NflStatsMetric[] | MlbStatsMetric[] | NhlStatsMetric[]
) => {
  const options =
    sport === Sport.nba
      ? sportMetrics[sport]
      : sport === Sport.nfl || sport === Sport.mlb
        ? // @ts-ignore
          sportMetrics[sport].reduce((arr: MetricOption[], group: MetricGroup) => {
            arr = arr.concat(group.options);
            return arr;
          }, [])
        : [];

  // @ts-ignore
  return metrics.map(metric => options.find((option: MetricOption) => option.value === metric)?.label).join(' + ');
};

const FavoriteProp = ({
  favorite,
  handleNavigation,
}: {
  favorite: Favorite;
  handleNavigation: (type: string) => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const [deleteFavorite, { isLoading: isDeleting }] = useDeleteFavoriteMutation();

  const handleSetBody = () => {
    handleNavigation(favorite.type);
    navigate(`/sports/${favorite.requestBody.sport}/${favorite.type}/players/${favorite.requestBody.playerId}`, {
      replace: true,
    });
    dispatch(setBody({ body: favorite.requestBody }));
  };

  const { data: nbaPlayer } = useGetNbaPlayerInfoQuery(
    { playerId: favorite.requestBody.playerId as string },
    { skip: favorite.requestBody.sport !== Sport.nba }
  );

  const { data: nflPlayer } = useGetNflPlayerInfoQuery(
    { playerId: favorite.requestBody.playerId as string },
    { skip: favorite.requestBody.sport !== Sport.nfl }
  );

  const { data: mlbPlayer } = useGetMlbPlayerInfoQuery(
    { playerId: favorite.requestBody.playerId as string },
    { skip: favorite.requestBody.sport !== Sport.mlb }
  );

  const { data: nhlPlayer } = useGetNhlPlayerInfoQuery(
    { playerId: favorite.requestBody.playerId as string },
    { skip: favorite.requestBody.sport !== Sport.nhl }
  );

  const player =
    favorite.requestBody.sport === Sport.nhl
      ? nhlPlayer
      : favorite.requestBody.sport === Sport.nba
        ? nbaPlayer
        : favorite.requestBody.sport === Sport.nfl
          ? nflPlayer
          : mlbPlayer;

  const handleDelete = async () => {
    try {
      await deleteFavorite({ id: favorite.id as string }).unwrap();
    } catch (e) {
      message.error('Failed to delete favorite');
    }
  };

  if (!player) return null;

  const playerPropCss = cn('favorite__player-prop', {
    'favorite__player-prop--mobile': isTabletOrMobile,
  });

  return (
    <div className={playerPropCss}>
      <Flex gap={8} align="center" style={{ marginBottom: 8 }}>
        <Button icon={<EyeFilled />} type="primary" shape="round" onClick={handleSetBody}>
          view
        </Button>
        <Button
          icon={<DeleteFilled />}
          type="text"
          shape="circle"
          danger={true}
          loading={isDeleting}
          onClick={handleDelete}
        >
          delete
        </Button>
      </Flex>
      <div className="favorite__player-prop__card">
        <FavoritePlayerHeader
          sport={favorite.requestBody.sport}
          player={player}
          pick={(favorite.picks || []).length ? favorite?.picks?.[0] : undefined}
        />
        <MarketHeader
          label={getMetricLabel(favorite.requestBody.sport, favorite.requestBody.metrics)}
          playerId={player?.body.playerID as string}
          team={player?.body.team as string}
          opponent={favorite.requestBody.vsOpponent as string}
          sport={favorite.requestBody.sport}
          threshold={favorite.requestBody.threshold ?? 0}
          metrics={favorite.requestBody.metrics || []}
          overUnder={favorite.requestBody.overUnder}
          noOfGames={12}
          compact={true}
        >
          <OverUnderDisplay
            overUnder={favorite.requestBody.overUnder}
            threshold={favorite.requestBody.threshold}
            compact={true}
          />
        </MarketHeader>
        <FavoritePlayerStats body={favorite.requestBody} />
        <Flex align="center" gap={8} style={{ width: '100%' }} className="favorite__bookmakers">
          {(favorite.picks || []).map((pick: PicksRecord) => (
            <BookmakerDisplay
              key={`${pick.bookmaker}-${pick.price}`}
              bookmaker={pick.bookmaker}
              price={pick.price}
              compact={true}
            >
              <Rating rating={pick.rating} />
            </BookmakerDisplay>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default FavoriteProp;
