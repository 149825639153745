import { baseApi } from './baseApi';
import {
  NflTeam,
  NflTeams,
  NflPlayers,
  NflPlayer,
  NflPlayerInfo,
  NbaStatsBody,
  NflPlayerStatResult,
} from '../../types';

export const nflApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getNflTeams: build.query<NflTeam[], void>({
      query: () => `/nfl/teams`,
      transformResponse: async (baseQueryReturnValue: Promise<NflTeams>) => {
        const teams = await baseQueryReturnValue;
        return teams.body;
      },
    }),
    getNflPlayers: build.query<NflPlayer[], void>({
      query: () => `/nfl/players`,
      transformResponse: async (baseQueryReturnValue: Promise<NflPlayers>) => {
        const players = await baseQueryReturnValue;
        return players.body.filter(
          player =>
            !!player.team &&
            player.pos !== 'C' &&
            player.pos !== 'OT' &&
            player.pos !== 'G' &&
            player.pos !== 'P' &&
            player.pos !== 'LS'
        );
      },
    }),
    getNflPlayerInfo: build.query<NflPlayerInfo, { playerId: string; getStats?: boolean }>({
      query: ({ playerId, getStats = true }) => `/nfl/players/${playerId}?getStats=${getStats}`,
    }),
    getNflPlayerStats: build.query<NflPlayerStatResult, { playerId: string; body: NbaStatsBody }>({
      query: ({ playerId, body }) => ({
        url: `/nfl/players/${playerId}/stats`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const { useGetNflPlayerStatsQuery, useGetNflTeamsQuery, useGetNflPlayersQuery, useGetNflPlayerInfoQuery } =
  nflApi;
