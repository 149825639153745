import React from 'react';
import { Flex, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import cn from 'classnames';
import { NhlTeam, OddsEvent } from 'src/types';
import { useGetNhlTeamsQuery } from 'src/redux/queries/nhlApi';

import './BettingEvent.scss';

const getTeam = (teams: NhlTeam[] | undefined, teamName: string): NhlTeam | null => {
  return (
    (teams || []).find((team: NhlTeam) => {
      return `${team.teamCity} ${team.teamName}` === teamName;
    }) || null
  );
};
function NHLEvent({ event, handleNavigate }: { event: OddsEvent; handleNavigate: (eventId: string) => void }) {
  const { data: teams } = useGetNhlTeamsQuery();

  const homeTeam = getTeam(teams, event.home_team);
  const awayTeam = getTeam(teams, event.away_team);

  const Team2 = ({ team, isHome }: { team: NhlTeam; isHome: boolean }) => {
    const logoCss = cn('betting__event-logo-avatar', {
      'betting__event-logo-avatar--home': isHome,
    });

    return (
      <div>
        <Flex align="flex-start">
          <div className="betting__event-logo">
            <div className={logoCss}>
              <img src={team?.espnLogo1} alt={team?.teamAbv} />
            </div>
            <div className="betting__event-team-wrapper">
              <div className="betting__event-team-name">
                {team?.teamName} <span className="city">{team?.teamCity}</span>
              </div>
              <div className="betting__event-win-loss">
                {team?.standings.W} - {team?.standings.L}
              </div>
            </div>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <div className="betting__event-wrapper">
      <div className="betting__event betting__event--secondary">
        <Flex align="center" justify="space-between">
          <Flex align="flex-start" vertical={true} justify="space-between">
            <Team2 team={homeTeam as NhlTeam} isHome />
            <Team2 team={awayTeam as NhlTeam} isHome={false} />
          </Flex>
          <Flex gap={8} vertical={true} align="center" justify="space-between">
            <div className="betting__event-start-time">{dayjs(event.commence_time).format('h:mm A')}</div>
            <div>
              <Button
                onClick={() => handleNavigate(event.id)}
                icon={<ArrowRightOutlined />}
                shape="round"
                type="primary"
                iconPosition="end"
              >
                Props
              </Button>
            </div>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default NHLEvent;
