import React, { useState } from 'react';
import { Flex, Select, Button } from 'antd';
import { useGetPicksMutation } from '../../redux/queries/adminApi';
import { Sport } from '../../types';

function Trades() {
  const [sport, setSport] = useState<Sport>(Sport.nfl);

  const [getPicks] = useGetPicksMutation();

  const handleGetPicks = async () => {
    await getPicks({ sport });
  };

  return (
    <div>
      <Select
        options={[
          { value: Sport.nfl, label: 'NFL' },
          { value: Sport.mlb, label: 'MLB' },
          { value: Sport.nhl, label: 'NHL' },
          { value: Sport.nba, label: 'NBA' },
        ]}
        value={sport}
        onChange={setSport}
      />
      <Flex align="center" gap={16} style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={handleGetPicks}>
          Get Picks
        </Button>
      </Flex>
    </div>
  );
}

export default Trades;
