import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import packageJson from '../../../package.json';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: ['User', 'Subscriptions', 'Favorites', 'MlbTeams', 'NbaTeams', 'NflTeams', 'NhlTeams', 'Trades', 'Picks'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: headers => {
      headers.set('X-App-Version', packageJson.version);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
