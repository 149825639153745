import React from 'react';
import { useGetNflTeamsQuery, useGetNflPlayerInfoQuery } from 'src/redux/queries/nflApi';
import PlayerHeader from '../PlayerHeader';
import { NflPlayer } from 'src/types';

type PlayerHeaderProps = {
  player: NflPlayer;
  handleExpandSection: (e: React.MouseEvent) => void;
  isExpanded: boolean;
};

export default function NFLPlayerHeader({ player, handleExpandSection, isExpanded }: PlayerHeaderProps) {
  const { data: teams, isFetching: isFetchingTeams } = useGetNflTeamsQuery();
  const { data: playerInfo, isFetching: isFetchingPlayerInfo } = useGetNflPlayerInfoQuery(
    { playerId: player?.playerID as string, getStats: false },
    { skip: !player?.playerID }
  );

  const playerTeam = teams?.find(team => team.teamAbv === player?.team) || null;

  if (!playerInfo) return null;

  return (
    <div>
      <PlayerHeader
        loading={isFetchingTeams || isFetchingPlayerInfo}
        playerName={playerInfo?.body.longName as string}
        headshot={playerInfo?.body.espnHeadshot as string}
        teamName={playerTeam?.teamName as string}
        teamCity={playerTeam?.teamCity as string}
        teamLogo={playerTeam?.espnLogo1 as string}
        position={playerInfo?.body.pos as string}
        jerseyNum={playerInfo?.body.jerseyNum as string}
        handleExpandSection={handleExpandSection}
        isExpanded={isExpanded}
      />
    </div>
  );
}
