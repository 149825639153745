import React from 'react';
import { Flex } from 'antd';
import { UserProfile } from '@clerk/clerk-react';
import { SyncOutlined } from '@ant-design/icons';
import Subscriptions from './Subscriptions';

import './MyAccount.scss';

function MyAccount() {
  return (
    <Flex justify="center" vertical={true} align="center" style={{ marginTop: 16, width: '100%' }}>
      <UserProfile>
        <UserProfile.Page label="Subscriptions" labelIcon={<SyncOutlined />} url="/home">
          <div className="my-account__header">Subscriptions</div>
          <Subscriptions />
        </UserProfile.Page>
      </UserProfile>
    </Flex>
  );
}

export default MyAccount;
