import { createSlice } from '@reduxjs/toolkit';
import { NbaStatsBody, Sport } from '../../types';
import { MIN_NO_OF_GAMES } from 'src/constants';

export interface ResearchState {
  body: NbaStatsBody;
}

const initialState: ResearchState = {
  body: {
    sport: Sport.nfl,
    team: null,
    playerId: null,
    metrics: [],
    overUnder: 'over',
    threshold: 0.5,
    noOfGames: MIN_NO_OF_GAMES,
    vsOpponent: null,
  },
};

export const researchSlice = createSlice({
  name: 'research',
  initialState,
  reducers: {
    resetBody: state => {
      state.body = initialState.body;
    },
    setBody: (state, { payload }) => {
      state.body = payload.body;
    },
    updateSport: (state, { payload }) => {
      state.body = {
        ...state.body,
        sport: payload.sport,
        team: null,
        playerId: null,
        metrics: [],
        overUnder: 'over',
        threshold: 0.5,
        noOfGames: MIN_NO_OF_GAMES,
        vsOpponent: null,
      };
    },
    updateTeam: (state, { payload }) => {
      state.body = {
        ...state.body,
        team: payload.team,
        playerId: null,
        metrics: [],
      };
    },
    updatePlayer: (state, { payload }) => {
      state.body = {
        ...state.body,
        playerId: payload.playerId,
        metrics: [],
      };
    },
    updateMetrics: (state, { payload }) => {
      state.body = {
        ...state.body,
        metrics: payload.metrics,
      };
    },
    updateOverUnder: (state, { payload }) => {
      state.body = {
        ...state.body,
        overUnder: payload.overUnder,
      };
    },
    updateThreshold: (state, { payload }) => {
      state.body = {
        ...state.body,
        threshold: payload.threshold,
      };
    },
    updateNoOfGames: (state, { payload }) => {
      state.body = {
        ...state.body,
        noOfGames: payload.noOfGames,
      };
    },
    updateOpponent: (state, { payload }) => {
      state.body = {
        ...state.body,
        vsOpponent: payload.team,
      };
    },
  },
});

export const {
  resetBody,
  setBody,
  updateSport,
  updatePlayer,
  updateTeam,
  updateMetrics,
  updateOverUnder,
  updateThreshold,
  updateNoOfGames,
  updateOpponent,
} = researchSlice.actions;

export default researchSlice.reducer;
