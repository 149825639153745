import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Flex, Button } from 'antd';
import { AreaChartOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Sport, NbaStatsBody, MlbStatsMetric, NbaStatsMetric, NflStatsMetric } from 'src/types';
import { setBody } from 'src/redux/slices/researchSlice';
import { setActiveTab } from 'src/redux/slices/tabsSlice';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

import './MarketHeader.scss';
import { NhlStatsMetric } from 'server/types';

function MarketHeader({
  label,
  sport,
  playerId,
  team,
  opponent,
  metrics,
  threshold,
  noOfGames = 3,
  isExpanded = false,
  overUnder = 'over',
  compact = false,
  handleSetExpanded,
  children,
}: {
  label: string | React.ReactNode;
  sport: Sport;
  playerId: string;
  team: string;
  opponent: string;
  metrics: MlbStatsMetric[] | NbaStatsMetric[] | NflStatsMetric[] | NhlStatsMetric[];
  threshold: number;
  overUnder?: 'over' | 'under';
  noOfGames?: number;
  isExpanded?: boolean;
  compact?: boolean;
  handleSetExpanded?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestBody: NbaStatsBody = useMemo(
    () => ({
      sport,
      team,
      playerId,
      vsOpponent: opponent,
      metrics,
      threshold: threshold ?? 0,
      overUnder: overUnder,
      noOfGames,
    }),
    [sport, team, playerId, opponent, metrics, threshold, noOfGames, overUnder]
  );

  const handleNavigate = useCallback(() => {
    dispatch(setBody({ body: requestBody }));
    dispatch(setActiveTab({ activeTab: `/sports/${sport}/research` }));
    navigate(`/sports/${sport}/research/players/${playerId}`);
  }, [requestBody, sport, playerId, dispatch, navigate]);

  const containerCss = classNames('market-header__container', {
    'market-header__container--compact': compact,
  });

  return (
    <div className={containerCss} onClick={handleSetExpanded && handleSetExpanded}>
      <Flex align="center" justify="space-between">
        <Flex align="center" gap={compact ? 8 : 16}>
          <div>
            {isTabletOrMobile || compact ? (
              <Button
                type="primary"
                size="small"
                shape="circle"
                icon={<AreaChartOutlined />}
                onClick={handleNavigate}
              />
            ) : (
              <Button type="primary" shape="round" size="small" icon={<AreaChartOutlined />} onClick={handleNavigate}>
                Research
              </Button>
            )}
          </div>

          <div className="market-header__title" onClick={handleSetExpanded && handleSetExpanded}>
            {label}
          </div>
        </Flex>
        {children && <>{children}</>}
        {handleSetExpanded && (
          <div>
            <Button
              type="primary"
              shape="circle"
              icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
              onClick={handleSetExpanded}
            />
          </div>
        )}
      </Flex>
    </div>
  );
}

export default MarketHeader;
