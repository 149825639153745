import React from 'react';
import { Skeleton, Avatar, Flex, Typography } from 'antd';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import './PlayerInfo.scss';

const { Title } = Typography;

type PlayerInfoProps = {
  headShot: string;
  teamLogo: string;
  displayName: string;
  isLoading: boolean;
  children: React.ReactNode;
};
const PlayerInfo = ({ isLoading, headShot, teamLogo, displayName, children }: PlayerInfoProps) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const containerCss = cn('player-info__container', {
    'player-info__container--mobile': isTabletOrMobile,
  });

  if (isLoading) {
    return (
      <div className={containerCss}>
        <div>
          <Skeleton.Avatar active={true} size={isTabletOrMobile ? 100 : 200} shape={'circle'} />
        </div>
        <Flex align="center" gap={8} vertical={isTabletOrMobile}>
          <div>
            <Skeleton.Avatar active={true} size={isTabletOrMobile ? 30 : 50} shape={'circle'} />
          </div>
          <div>
            <Skeleton.Input active={true} block={true} />
          </div>
        </Flex>
      </div>
    );
  }

  return (
    <div className={containerCss}>
      <div>
        <Avatar style={{ backgroundColor: '#333' }} src={headShot} size={isTabletOrMobile ? 150 : 200} />
      </div>
      <Flex align="center" gap={8} vertical={true}>
        <Flex align="center" gap={8}>
          <div>
            <Avatar style={{ backgroundColor: '#333' }} src={teamLogo} size={isTabletOrMobile ? 30 : 50} />
          </div>
          <div>
            <Title level={5} style={{ margin: 0 }}>
              {displayName}
            </Title>
          </div>
        </Flex>
        {children}
      </Flex>
    </div>
  );
};

export default PlayerInfo;
