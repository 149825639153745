import React from 'react';
import { OddsEvent } from 'src/types';
import MLBEvent from './Events/MLBEvent';
import NFLEvent from './Events/NFLEvent';
import NBAEvent from './Events/NBAEvent';
import NHLEvent from './Events/NHLEvent';

function BettingEvent({ event, handleNavigate }: { event: OddsEvent; handleNavigate: (eventId: string) => void }) {
  return event.sport_title === 'MLB' ? (
    <MLBEvent event={event} handleNavigate={handleNavigate} />
  ) : event.sport_title === 'NFL' ? (
    <NFLEvent event={event} handleNavigate={handleNavigate} />
  ) : event.sport_title === 'NBA' ? (
    <NBAEvent event={event} handleNavigate={handleNavigate} />
  ) : event.sport_title === 'NHL' ? (
    <NHLEvent event={event} handleNavigate={handleNavigate} />
  ) : (
    <>Sport not supported</>
  );
}

export default BettingEvent;
