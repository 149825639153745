import React from 'react';
import { InputNumber, Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import debounce from 'lodash.debounce';
import { MOBILE_QUERY } from '../../constants';
const { Option } = Select;

const OverUnder = ({
  overUnder,
  threshold,
  onOverUnderChange,
  onThresholdChange,
}: {
  overUnder: 'over' | 'under';
  threshold: number | null;
  onOverUnderChange: (value: 'over' | 'under') => void;
  onThresholdChange: (value: number | null) => void;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const handleInputChange = (value: number | null) => {
    debouncedOnChange(value);
  };

  const debouncedOnChange = debounce(onThresholdChange, 2000);

  const selectBefore = (
    <Select
      variant="filled"
      defaultValue="over"
      value={overUnder}
      size={isTabletOrMobile ? 'large' : 'middle'}
      style={{ width: isTabletOrMobile ? 90 : 90 }}
      onChange={onOverUnderChange}
    >
      <Option value="over">Over</Option>
      <Option value="under">Under</Option>
    </Select>
  );

  return (
    <InputNumber
      variant="filled"
      addonBefore={selectBefore}
      defaultValue={threshold || 0}
      step={0.5}
      min={0}
      onChange={handleInputChange}
      size={isTabletOrMobile ? 'large' : 'middle'}
      style={{ width: isTabletOrMobile ? '100%' : 200 }}
    />
  );
};

export default OverUnder;
