import React from 'react';
import { MlbStatsMetric, NbaStatsMetric, NflStatsMetric, NhlStatsMetric, Sport } from '../../types';
import { Select, SelectProps } from 'antd';
import { useGetNflPlayerInfoQuery } from '../../redux/queries/nflApi';
import { useGetMlbPlayerInfoQuery } from '../../redux/queries/mlbApi';
import { useGetNhlPlayerInfoQuery } from '../../redux/queries/nhlApi';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

export type MetricOption = {
  label: string;
  value: NbaStatsMetric | NflStatsMetric | MlbStatsMetric | NhlStatsMetric;
};

export type MetricGroup = {
  label: React.ReactNode;
  title: string;
  options: MetricOption[];
};

export type SportMetrics = Record<Sport, MetricOption[] | MetricGroup[]>;

export const nflMetricsByPosition: Record<string, string[]> = {
  QB: ['passing', 'receiving', 'rushing', 'fumbles', 'fantasy'],
  RB: ['rushing', 'receiving', 'fumbles', 'fantasy'],
  FB: ['rushing', 'receiving', 'fumbles', 'fantasy'],
  WR: ['receiving', 'rushing', 'fumbles', 'fantasy'],
  TE: ['receiving', 'rushing', 'fumbles', 'fantasy'],
  PK: ['kicking', 'fantasy'],
  LB: ['defense'],
  DT: ['defense'],
  CB: ['defense'],
  S: ['defense'],
};

export const sportMetrics: SportMetrics = {
  [Sport.nba]: [
    {
      label: 'Points/Rebounds/Assists',
      value: NbaStatsMetric.PRA,
    },
    {
      label: 'Triple Double',
      value: NbaStatsMetric.TRIPLE,
    },
    {
      label: 'Double Double',
      value: NbaStatsMetric.DOUBLE,
    },
    {
      label: 'Points',
      value: NbaStatsMetric.PTS,
    },
    {
      label: 'Assists',
      value: NbaStatsMetric.AST,
    },
    {
      label: 'Rebounds',
      value: NbaStatsMetric.REB,
    },
    {
      label: '3 Pointers Made',
      value: NbaStatsMetric.FG3M,
    },
    {
      label: 'Blocks',
      value: NbaStatsMetric.BLK,
    },
    {
      label: 'Steals',
      value: NbaStatsMetric.STL,
    },
    {
      label: 'Turnovers',
      value: NbaStatsMetric.TOV,
    },
  ],
  [Sport.nfl]: [
    {
      label: <span>PASSING</span>,
      title: 'passing',
      options: [
        { label: 'Passing Tds', value: NflStatsMetric.PASSING_TD },
        { label: 'Passing Yds', value: NflStatsMetric.PASSING_YDS },
        { label: 'Passing Att', value: NflStatsMetric.PASSING_ATTEMPTS },
        { label: 'Passing Comp', value: NflStatsMetric.PASSING_COMP },
        { label: 'Passing Avg', value: NflStatsMetric.PASSING_AVG },
        { label: 'Passing Ints', value: NflStatsMetric.PASSING_INT },
        { label: 'Sacks Taken', value: NflStatsMetric.PASSING_SACKED },
      ],
    },
    {
      label: <span>RECEIVING</span>,
      title: 'receiving',
      options: [
        { label: 'Receiving Tds', value: NflStatsMetric.RECEIVING_TD },
        { label: 'Receiving Yds', value: NflStatsMetric.RECEIVING_YDS },
        { label: 'Receiving Targets', value: NflStatsMetric.RECEIVING_TARGETS },
        { label: 'Receiving Rec', value: NflStatsMetric.RECEIVING_RECS },
        { label: 'Receiving Avg', value: NflStatsMetric.RECEIVING_AVG },
        { label: 'Passing Long Rec', value: NflStatsMetric.RECEIVING_LONG_REC },
      ],
    },
    {
      label: <span>RUSHING</span>,
      title: 'rushing',
      options: [
        { label: 'Rushing Tds', value: NflStatsMetric.RUSH_TD },
        { label: 'Rushing Yds', value: NflStatsMetric.RUSH_YDS },
        { label: 'Rushing Carr', value: NflStatsMetric.RUSH_CARRIES },
        { label: 'Rushing Avg', value: NflStatsMetric.RUSH_AVG },
        { label: 'Rushing Long Rush', value: NflStatsMetric.RUSH_LONG_RUSH },
      ],
    },
    {
      label: <span>KICKING</span>,
      title: 'kicking',
      options: [
        { label: 'Kicking Fg Attempts', value: NflStatsMetric.KICKING_FG_ATTEMPTS },
        { label: 'Kicking Fg Made', value: NflStatsMetric.KICKING_FG_MADE },
        { label: 'Kicking Fg Long', value: NflStatsMetric.KICKING_FG_LONG },
        { label: 'Kicking Fg Pct', value: NflStatsMetric.KICKING_FG_PCT },
        { label: 'Kicking Xp Attempts', value: NflStatsMetric.KICKING_XP_ATTEMPTS },
        { label: 'Kicking Xp Made', value: NflStatsMetric.KICKING_XP_MADE },
        { label: 'Kicking Points', value: NflStatsMetric.KICKING_PTS },
      ],
    },
    {
      label: <span>DEFENSE</span>,
      title: 'defense',
      options: [
        { label: 'Def Tackles', value: NflStatsMetric.DEFENSE_TOTAL_TACKLES },
        { label: 'Def Td', value: NflStatsMetric.DEFENSE_DEF_TD },
        { label: 'Def Solo Tackles', value: NflStatsMetric.DEFENSE_SOLO_TACKLES },
        { label: 'Def Tfl', value: NflStatsMetric.DEFENSE_TFL },
        { label: 'Def Qb Hits', value: NflStatsMetric.DEFENSE_QB_HITS },
        { label: 'Def Ints', value: NflStatsMetric.DEFENSE_DEF_INTERCEPTIONS },
        { label: 'Def Sacks', value: NflStatsMetric.DEFENSE_SACKS },
        { label: 'Def Pass Deflections', value: NflStatsMetric.DEFENSE_PASS_DEFLECTIONS },
      ],
    },
    {
      label: <span>FANTASY</span>,
      title: 'fantasy',
      options: [
        { label: 'Fantasy Ppr', value: NflStatsMetric.FANTASY_PPR },
        { label: 'Fantasy Half Ppr', value: NflStatsMetric.FANTASY_HALF_PPR },
        { label: 'Fantasy Standard', value: NflStatsMetric.FANTASY_STANDARD },
      ],
    },
    {
      label: <span>FUMBLES</span>,
      title: 'fumbles',
      options: [
        { label: 'Fumbles Lost', value: NflStatsMetric.FUMBLES_LOST },
        { label: 'Fumbles', value: NflStatsMetric.FUMBLES },
        { label: 'Fumbles Recovered', value: NflStatsMetric.FUMBLES_RECOVERED },
      ],
    },
  ],
  [Sport.mlb]: [
    {
      label: <span>PITCHING</span>,
      title: 'pitching',
      options: [
        { label: 'Pitching outs', value: MlbStatsMetric.PITCHING_OUTS },
        { label: 'Strike outs', value: MlbStatsMetric.PITCHING_SO },
        { label: 'Flyouts', value: MlbStatsMetric.PITCHING_FLYOUTS },
        { label: 'Groundouts', value: MlbStatsMetric.PITCHING_GROUNDOUTS },
        { label: 'Walks', value: MlbStatsMetric.PITCHING_BB },
        { label: 'Balks', value: MlbStatsMetric.PITCHING_BALK },
        { label: 'Hits Allowed', value: MlbStatsMetric.PITCHING_H },
        { label: 'Home Runs Allowed', value: MlbStatsMetric.PITCHING_HR },
        { label: 'Runs Allowed', value: MlbStatsMetric.PITCHING_R },
        { label: 'Pitch count', value: MlbStatsMetric.PITCHING_PITCHES },
      ],
    },
    {
      label: <span>HITTING</span>,
      title: 'hitting',
      options: [
        { label: 'Home Runs', value: MlbStatsMetric.HITTING_HR },
        { label: 'Hits', value: MlbStatsMetric.HITTING_H },
        { label: 'RBIs', value: MlbStatsMetric.HITTING_RBI },
        { label: 'Triples', value: MlbStatsMetric.HITTING_3B },
        { label: 'Doubles', value: MlbStatsMetric.HITTING_2B },
        { label: 'Runs', value: MlbStatsMetric.HITTING_R },
        { label: 'Total Bases', value: MlbStatsMetric.HITTING_TB },
        { label: 'Intentional Walks', value: MlbStatsMetric.HITTING_IBB },
        { label: 'Walks', value: MlbStatsMetric.HITTING_BB },
        { label: 'Strike outs', value: MlbStatsMetric.HITTING_SO },
      ],
    },
    {
      label: <span>BASE RUNNING</span>,
      title: 'baserunning',
      options: [
        { label: 'Stolen bases', value: MlbStatsMetric.BASE_RUNNING_SB },
        { label: 'Caught Stealing', value: MlbStatsMetric.BASE_RUNNING_CS },
        { label: 'Putout', value: MlbStatsMetric.BASE_RUNNING_PO },
      ],
    },
  ],
  [Sport.nhl]: [
    {
      label: <span>GOALIE</span>,
      title: 'goalie',
      options: [
        { label: 'Saves', value: NhlStatsMetric.GOALIE_SAVES },
        { label: 'Powerplay Saves', value: NhlStatsMetric.GOALIE_POWERPLAY_SAVES },
        { label: 'Short Handed Saves', value: NhlStatsMetric.GOALIE_SHORT_HANDED_SAVES },
        { label: 'Goals Against', value: NhlStatsMetric.GOALIE_GOALS_AGAINST },
        { label: 'Shots Against', value: NhlStatsMetric.GOALIE_SHOTS_AGAINST },
      ],
    },
    {
      label: <span>Metric</span>,
      title: 'metric',
      options: [
        { label: 'Points', value: NhlStatsMetric.POINTS },
        { label: 'Powerplay Points', value: NhlStatsMetric.POWERPLAY_POINTS },

        { label: 'Goals', value: NhlStatsMetric.GOALS },
        { label: 'Powerplay Goals', value: NhlStatsMetric.POWERPLAY_GOALS },
        { label: 'Shootout Goals', value: NhlStatsMetric.SHOOTOUT_GOALS },

        { label: 'Blocked Shots', value: NhlStatsMetric.BLOCKED_SHOTS },
        { label: 'Shots', value: NhlStatsMetric.SHOTS },
        { label: 'Shots missed Net', value: NhlStatsMetric.SHOTS_MISSED_NET },

        { label: 'Assists', value: NhlStatsMetric.ASSISTS },
        { label: 'Powerplay Assists', value: NhlStatsMetric.POWERPLAY_ASSISTS },
      ],
    },
  ],
};

const SelectMetric = ({
  sport,
  metrics,
  playerId,
  onChange,
}: {
  sport: Sport;
  metrics: NbaStatsMetric[] | NflStatsMetric[] | MlbStatsMetric[] | NhlStatsMetric[];
  playerId: string | null;
  onChange: (value: NbaStatsMetric[] | NflStatsMetric[] | MlbStatsMetric[] | NhlStatsMetric[]) => void;
}) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const { data: nflPlayerInfo, isFetching: nflPlayerInfoFetching } = useGetNflPlayerInfoQuery(
    { playerId: playerId as string },
    { skip: !playerId || sport !== Sport.nfl }
  );
  const { data: mlbPlayerInfo, isFetching: mlbPlayerInfoFetching } = useGetMlbPlayerInfoQuery(
    { playerId: playerId as string },
    { skip: !playerId || sport !== Sport.mlb }
  );
  const { data: nhlPlayerInfo, isFetching: nhlPlayerInfoFetching } = useGetNhlPlayerInfoQuery(
    { playerId: playerId as string },
    { skip: !playerId || sport !== Sport.nhl }
  );

  const onSearch = (value: string) => {
    // console.log('search:', value);
  };

  const filterOption = (input: string, option?: any) => {
    const isGroup = Array.isArray(option.options);
    if (isGroup) {
      return false;
    }
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  const getOptions = (): SelectProps['options'] => {
    if (sport === Sport.nba) {
      // @ts-ignore
      if (metrics.includes(NbaStatsMetric.PRA)) {
        return sportMetrics[sport].map(option => ({
          ...option,
          // @ts-ignore
          disabled: option.value !== NbaStatsMetric.PRA,
        }));
      }

      // @ts-ignore
      if (metrics.includes(NbaStatsMetric.TRIPLE)) {
        return sportMetrics[sport].map(option => ({
          ...option,
          // @ts-ignore
          disabled: option.value !== NbaStatsMetric.TRIPLE,
        }));
      }

      // @ts-ignore
      if (metrics.includes(NbaStatsMetric.DOUBLE)) {
        return sportMetrics[sport].map(option => ({
          ...option,
          // @ts-ignore
          disabled: option.value !== NbaStatsMetric.DOUBLE,
        }));
      }
    }

    if (sport === Sport.nfl) {
      if (!nflPlayerInfo?.body.pos) return [];
      // @ts-ignore
      return nflMetricsByPosition[nflPlayerInfo.body.pos].map(metric => {
        // @ts-ignore
        return sportMetrics[Sport.nfl].find((option: MetricGroup) => option.title === metric);
      });
    }

    if (sport === Sport.mlb) {
      if (!mlbPlayerInfo?.body.pos) return [];
      // @ts-ignore
      return (mlbPlayerInfo.body.pos === 'P' ? ['pitching', 'hitting', 'baserunning'] : ['hitting', 'baserunning']).map(
        metric => {
          // @ts-ignore
          return sportMetrics[Sport.mlb].find((option: MetricGroup) => option.title === metric);
        }
      );
    }

    if (sport === Sport.nhl) {
      if (!nhlPlayerInfo?.body.pos) return [];

      // @ts-ignore
      if (metrics.includes(NhlStatsMetric.POINTS)) {
        return sportMetrics[sport].map(option => ({
          ...option,
          // @ts-ignore
          options: option.options.map(o => ({ ...o, disabled: o.value !== NhlStatsMetric.POINTS })),
        }));
      }
    }

    return sportMetrics[sport];
  };

  return (
    <Select
      variant="filled"
      mode="multiple"
      allowClear
      placeholder="All Metrics"
      optionFilterProp="children"
      value={metrics}
      loading={nflPlayerInfoFetching || mlbPlayerInfoFetching || nhlPlayerInfoFetching}
      onChange={onChange}
      onSearch={onSearch}
      filterOption={filterOption}
      // @ts-ignore
      options={getOptions()}
      size={isTabletOrMobile ? 'large' : 'middle'}
      style={{ width: isTabletOrMobile ? '100%' : 235 }}
    />
  );
};

export default SelectMetric;
