import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Button, Flex, Collapse, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import cn from 'classnames';
import SelectTeams from './SelectTeams';
import SelectMetric from './SelectMetric';
import OverUnder from './OverUnder';
import PlayerListing from './PlayerListing';
import VsOpponent from './VsOpponent';
import { NbaStatsBody, NbaStatsMetric, NflStatsMetric, MlbStatsMetric, NhlStatsMetric } from '../../types';
import PlayerTrends from '../PlayerTrends/PlayerTrends';
import {
  updatePlayer,
  updateMetrics,
  updateOverUnder,
  updateThreshold,
  updateTeam,
  updateOpponent,
} from '../../redux/slices/researchSlice';

import './PlayerSelection.scss';
import { RootState } from 'src/redux/store';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { useGetUserInfo } from '../../hooks/useGetUser';

const { Title } = Typography;

const PlayerSelection = () => {
  const {
    user: { basicPlan, proPlan },
  } = useGetUserInfo();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const requestBody: NbaStatsBody = useSelector((state: RootState) => state.research.body);

  const handleSetPlayerId = (playerId: string | null) => {
    dispatch(updatePlayer({ playerId }));
  };

  const handleSetMetrics = (
    metrics: (NbaStatsMetric[] | NflStatsMetric[] | MlbStatsMetric[] | NhlStatsMetric[]) | null
  ) => {
    dispatch(updateMetrics({ metrics }));
  };

  const handleSetOverUnder = (overUnder: 'over' | 'under') => {
    dispatch(updateOverUnder({ overUnder }));
  };

  const handleSetThreshold = (threshold: number | null) => {
    dispatch(updateThreshold({ threshold }));
  };

  const handleSetTeam = (team: string | null) => {
    dispatch(updateTeam({ team }));
  };

  const handleSetVsOpponent = (team: string | null) => {
    dispatch(updateOpponent({ team }));
  };

  const isValid = () => {
    return (
      requestBody.playerId &&
      (requestBody.metrics || []).length > 0 &&
      (requestBody.threshold === 0 || requestBody.threshold)
    );
  };

  const handleGetPlayerData = () => {
    navigate(`/sports/${requestBody.sport}/research/players/${requestBody.playerId}`, {
      replace: true,
    });
  };

  const SelectionBody = () => {
    const filterSelectionCss = cn('player-selection__filters', {
      'player-selection__filters--mobile': isTabletOrMobile,
    });
    return (
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <div className={filterSelectionCss}>
          <Flex
            gap={16}
            align={'center'}
            vertical={isTabletOrMobile}
            style={{ flexWrap: isTabletOrMobile ? 'nowrap' : 'wrap' }}
          >
            <div className="filter">
              {requestBody.sport && (
                <SelectTeams sport={requestBody.sport} team={requestBody.team} onChange={handleSetTeam} />
              )}
            </div>
            <div className="filter">
              {requestBody.sport && (
                <PlayerListing
                  sport={requestBody.sport}
                  team={requestBody.team}
                  playerId={requestBody.playerId}
                  onChange={handleSetPlayerId}
                />
              )}
            </div>
            {(basicPlan || proPlan) && (
              <div className="filter">
                {requestBody.sport && (
                  <VsOpponent
                    sport={requestBody.sport}
                    team={requestBody.team}
                    opponent={requestBody.vsOpponent}
                    onChange={handleSetVsOpponent}
                  />
                )}
              </div>
            )}
            <Flex align="center" gap={16} style={{ width: isTabletOrMobile ? '100%' : 'auto' }}>
              <div className="filter">
                {requestBody.sport && (
                  <SelectMetric
                    sport={requestBody.sport}
                    metrics={requestBody.metrics}
                    playerId={requestBody.playerId}
                    onChange={handleSetMetrics}
                  />
                )}
              </div>
              <div className="filter">
                <OverUnder
                  overUnder={requestBody.overUnder}
                  threshold={requestBody.threshold}
                  onOverUnderChange={handleSetOverUnder}
                  onThresholdChange={handleSetThreshold}
                />
              </div>
            </Flex>
            {!isTabletOrMobile && (
              <div>
                <Button
                  type="primary"
                  shape="circle"
                  disabled={!isValid()}
                  onClick={handleGetPlayerData}
                  icon={<ArrowRightOutlined />}
                />
              </div>
            )}
          </Flex>
        </div>
      </div>
    );
  };

  const genExtra = () => (
    <Button
      type="primary"
      size="small"
      disabled={!isValid()}
      onClick={handleGetPlayerData}
      icon={<ArrowRightOutlined />}
    >
      Get Data
    </Button>
  );

  return (
    <>
      {isTabletOrMobile ? (
        <Collapse
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: (
                <Title level={5} style={{ margin: 0, padding: 0 }}>
                  Selection Settings
                </Title>
              ),
              children: <SelectionBody />,
              extra: genExtra(),
            },
          ]}
        />
      ) : (
        <div>
          <SelectionBody />
        </div>
      )}
      {isValid() && (
        <Routes>
          <Route
            path={`/research/players/:playerId`}
            element={<PlayerTrends playerId={requestBody.playerId ?? ''} body={requestBody} />}
          />
        </Routes>
      )}
    </>
  );
};

export default PlayerSelection;
