import { NbaStatsBody } from './prop-request-body';

export enum NbaStatsMetric {
  DOUBLE = 'DOUBLE DOUBLE',
  TRIPLE = 'TRIPLE DOUBLE',
  PRA = ' PTS/REB/AST',
  FG3M = 'tptfgm',
  REB = 'reb',
  AST = 'ast',
  TOV = 'TOV',
  STL = 'stl',
  BLK = 'blk',
  PTS = 'pts',
}

export type NbaPlayerStats = {
  blk: string;
  OffReb: string;
  ftp: string;
  DefReb: string;
  plusMinus: string;
  stl: string;
  pts: string;
  tech: string;
  team: string;
  TOV: string;
  fga: string;
  ast: string;
  tptfgp: string;
  teamAbv: string;
  mins: string;
  fgm: string;
  fgp: string;
  reb: string;
  teamID: string;
  tptfgm: string;
  fta: string;
  tptfga: string;
  longName: string;
  PF: string;
  playerID: string;
  ftm: string;
  gameID: string;
  fantasyPoints: string;
};

export type NbaGameStat = Record<string, NbaPlayerStats>;

export type NbaGameStats = {
  statusCode: number;
  body: NbaGameStat;
};

export type NbaStatTypes = 'away' | 'home' | 'all' | 'vs';

export type NbaStatType = Record<
  NbaStatTypes,
  {
    percentage: number;
    stats: {
      game: string;
      gameDate: string;
      total: number;
      thresholdMet: boolean;
      metaData: NbaPlayerStats;
    }[];
  }
>;

export type NbaPlayerStatResult = {
  stats: NbaStatType;
  criteria: NbaStatsBody;
  rating: number | null;
};
