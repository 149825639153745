import React from 'react';
import cn from 'classnames';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { MlbTeam, NflTeam, NbaTeam } from '../../types';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

import './MarketTeam.scss';

type Team = Partial<MlbTeam> | NflTeam | NbaTeam;
function MarketTeams({
  homeTeam,
  awayTeam,
  startTime,
}: {
  homeTeam: Team | null;
  awayTeam: Team | null;
  startTime: string;
}) {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const startTimeCss = cn('market-team__start-time', {
    'market-team__start-time--mobile': isTabletOrMobile,
  });

  return (
    <div className="market-teams__wrapper">
      {isTabletOrMobile && (
        <div className={startTimeCss}>
          {dayjs(startTime).format(isTabletOrMobile ? 'MMMM D, h:mm A' : 'dddd, MMMM D, YYYY h:mm A')}
        </div>
      )}
      <div className="market-team__container">
        <div>
          <Flex align="center">
            <div className="market-team__logo home-team__logo">
              <img src={homeTeam?.espnLogo1} alt={homeTeam?.teamName} />
            </div>
            <div className="market-team__name market-team__name--home">
              <div>{homeTeam?.teamName}</div>
              <div className="market-team__city">{homeTeam?.teamCity}</div>
              <div className="market-team__wins">
                {homeTeam?.wins} - {homeTeam?.loss}
              </div>
            </div>
          </Flex>
        </div>
        {!isTabletOrMobile && (
          <div className={startTimeCss}>{dayjs(startTime).format('dddd, MMMM D, YYYY h:mm A')}</div>
        )}
        <div className="market-team__away">
          <Flex align="center" justify="flex-end">
            <div className="market-team__name">
              <div>{awayTeam?.teamName}</div>
              <div className="market-team__city">{awayTeam?.teamCity}</div>
              <div className="market-team__wins">
                {awayTeam?.wins} - {awayTeam?.loss}
              </div>
            </div>
            <div className="market-team__logo market-team__logo--away">
              <img src={awayTeam?.espnLogo1} alt={awayTeam?.teamName} />
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
}

export default MarketTeams;
