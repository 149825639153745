import { Favorite, NbaStatsBody } from '../../types';
import { baseApi } from './baseApi';
import { PicksRecord } from 'src/components/Betting/OurPicks/OurPicks';

export const favoritesApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getFavorites: build.query<Favorite[], void>({
      query: () => '/user/favorites',
      providesTags: ['Favorites'],
    }),
    createFavorite: build.mutation<
      Favorite,
      { requestBody: NbaStatsBody; type: 'research' | 'betting'; picks?: PicksRecord[] }
    >({
      query: ({ requestBody, type, picks = [] }) => ({
        url: '/user/favorites',
        method: 'POST',
        body: { requestBody, type, picks },
      }),
      invalidatesTags: ['Favorites'],
    }),
    deleteFavorite: build.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/user/favorites/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorites'],
    }),
    deleteAllFavorites: build.mutation<any, void>({
      query: () => ({
        url: '/user/favorites/clearall',
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorites'],
    }),
  }),
});

export const {
  useGetFavoritesQuery,
  useCreateFavoriteMutation,
  useDeleteFavoriteMutation,
  useDeleteAllFavoritesMutation,
} = favoritesApi;
