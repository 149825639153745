import React from 'react';
import { NbaStatsBody, Sport } from '../../types';
import { useSelector } from 'react-redux';

import NbaPlayer from './NbaPlayer';
import NflPlayer from './NflPlayer';
import MlbPlayer from './MlbPlayer';
import NhlPlayer from './NhlPlayer';
import { RootState } from 'src/redux/store';

import './PlayerTrends.scss';

const PlayerTrends = ({ playerId, body }: { playerId: string | null; body: NbaStatsBody }) => {
  const requestBody: NbaStatsBody = useSelector((state: RootState) => state.research.body);

  return (
    <div className="player-trends__container">
      {requestBody.sport === Sport.nba && <NbaPlayer playerId={playerId} statsBody={body} />}
      {requestBody.sport === Sport.nfl && <NflPlayer playerId={playerId} statsBody={body} />}
      {requestBody.sport === Sport.mlb && <MlbPlayer playerId={playerId} statsBody={body} />}
      {requestBody.sport === Sport.nhl && <NhlPlayer playerId={playerId} statsBody={body} />}
    </div>
  );
};

export default PlayerTrends;
