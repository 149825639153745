import {
  NbaPlayers,
  NbaPlayer,
  NbaTeams,
  NbaTeam,
  NbaPlayerInfo,
  NbaPlayerStatResult,
  NbaStatsBody,
} from '../../types';
import { baseApi } from './baseApi';

export const nbaApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getNbaTeams: build.query<NbaTeam[], void>({
      query: () => `/nba/teams`,
      transformResponse: async (baseQueryReturnValue: Promise<NbaTeams>) => {
        const teams = await baseQueryReturnValue;
        return teams.body;
      },
    }),
    getNbaPlayers: build.query<NbaPlayer[], void>({
      query: () => `/nba/players`,
      transformResponse: async (baseQueryReturnValue: Promise<NbaPlayers>) => {
        const players = await baseQueryReturnValue;
        return players.body.filter(player => !!player.team);
      },
    }),
    getNbaPlayerInfo: build.query<NbaPlayerInfo, { playerId: string }>({
      query: ({ playerId }) => `/nba/players/${playerId}`,
    }),
    getNbaPlayerStats: build.query<NbaPlayerStatResult, { playerId: string; body: NbaStatsBody }>({
      query: ({ playerId, body }) => ({
        url: `/nba/players/${playerId}/stats`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const { useGetNbaTeamsQuery, useGetNbaPlayersQuery, useGetNbaPlayerInfoQuery, useGetNbaPlayerStatsQuery } =
  nbaApi;
