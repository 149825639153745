import { useUser } from '@clerk/clerk-react';
import { useCurrentUserQuery } from 'src/redux/queries/userApi';
import { User } from '../types';

export const useGetUserInfo = (): {
  user: User;
  isLoading: boolean;
} => {
  const user = useUser();
  const { data, isLoading } = useCurrentUserQuery(undefined, { skip: !user.isSignedIn });

  return {
    isLoading,
    user: {
      isSignedIn: user.isSignedIn && data?.emailAddress,
      ...(data || { basicPlan: false, proPlan: false }),
    },
  };
};
