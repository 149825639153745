import React from 'react';
import PlayerSelection from '../PlayerSelection/PlayerSelection';

function Research() {
  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <PlayerSelection />
    </div>
  );
}

export default Research;
