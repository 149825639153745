import React from 'react';
import cn from 'classnames';
import { Routes, Route } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { StickyContainer, Sticky } from 'react-sticky';
import MyAccount from '../Login/MyAccount';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import SignUp from '../Login/SignUp';
import SignIn from '../Login/SignIn';
import { MOBILE_QUERY } from '../../constants';

import './App.scss';

import Home from '../Home/Home';
import SportSelection from '../SportSelection/SportSelection';
import SportLanding from '../SportSelection/SportLanding';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Terms from '../Footer/Terms';
import Privacy from '../Footer/Privacy';
import Pricing from '../Pricing/Pricing';
import Admin from '../Admin/Admin';
import { useGetUserInfo } from '../../hooks/useGetUser';

const App = () => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const appLayoutCss = cn('app__layout', {
    'app__layout--mobile': isTabletOrMobile,
  });

  const { user } = useGetUserInfo();

  return (
    <div className="app__body">
      <StickyContainer>
        <Sticky>
          {({ style }) => (
            <div style={style} className="app-header__wrapper ">
              <Header />
            </div>
          )}
        </Sticky>
        <div className={appLayoutCss}>
          <div className="app__center">
            <SignedIn>
              {isTabletOrMobile && <SportSelection />}
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/sports/:sportId/*" element={<SportLanding />} />
                <Route path="/pricing" element={<Pricing />} />
                {user?.isAdmin && <Route path="/admin/*" element={<Admin />} />}
                <Route path="*" element={<Home />} />
              </Routes>
            </SignedIn>
            <SignedOut>
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="*" element={<Home />} />
              </Routes>
            </SignedOut>
          </div>
          {/* <div className="app__right"></div> */}
        </div>
        <Footer />
      </StickyContainer>
    </div>
  );
};

export default App;
