import React from 'react';
import dayjs from 'dayjs';
import { useGetUserSubscriptionsQuery, useCancelSubscriptionMutation } from '../../redux/queries/userApi';
import { UserSubscriptions } from '../../types';
import { Tag, Flex, Button, message, Popconfirm } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';

import './Subscriptions.scss';

const formattedNumber = (number: number) => number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

function Subscriptions() {
  const { data = [], isLoading: subscriptionsIsLoading } = useGetUserSubscriptionsQuery();
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();

  const confirm = async (e: any, subscriptionId: string) => {
    console.log(e, subscriptionId);
    await cancelSubscription({ subscriptionId }).unwrap();
    message.success('Your subscription has been cancelled successfully!');

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  console.log('data', data);
  return (
    <div className="subscriptions__container">
      {!subscriptionsIsLoading && data.length === 0 && (
        <div className="subscriptions__empty">No subscriptions found</div>
      )}
      {data?.map((subscription: UserSubscriptions) => (
        <Flex
          className="subscription__container"
          key={subscription.id}
          align="flex-start"
          justify="space-between"
          vertical={isTabletOrMobile}
          gap={16}
        >
          <Flex vertical={true} gap={8}>
            <Flex align="center" gap={16} className="subscriptions__name-container">
              <div className="subscriptions__name">
                {subscription.name}{' '}
                <span className="subscriptions__price">
                  ({formattedNumber(subscription.price)} {subscription.currency}/{subscription.interval}ly)
                </span>
              </div>
              <div>
                <Tag color="#87d068">{subscription.status}</Tag>
              </div>
            </Flex>
            <div className="subscriptions__current-end">
              Started: {`${dayjs(new Date(subscription.start_date)).format('MM/DD/YYYY')}`}
            </div>
            <div className="subscriptions__current-end">
              Currend End: {`${dayjs(new Date(subscription.current_period_end)).format('MM/DD/YYYY')}`}
            </div>
          </Flex>
          <div className="subscriptions__cancel">
            <Popconfirm
              title="Cancel your subscription?"
              description="Are you sure to cancel your subscription?"
              onConfirm={e => confirm(e, subscription.id)}
              okButtonProps={{ loading: isLoading }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger size="small">
                Cancel Subscribtion
              </Button>
            </Popconfirm>
            <div className="subscriptions__cancel-note">
              *If you choose to cancel, your access will remain until the end of your billing period indicated above.
            </div>
          </div>
        </Flex>
      ))}
    </div>
  );
}

export default Subscriptions;
