import React from 'react';
import ScrollToTop from './ScrollToTop';
import './Page.scss';

const lineItems: { title: string; content: React.ReactNode }[] = [
  {
    title: 'INTRODUCTION',
    content: (
      <>
        Welcome to ProStatTrends, LLC. (referred to in these terms as “we”, “our” and “us”)!
        <br />
        <br />
        These Terms of Service (“Terms”, “Terms of Service”) govern your use of our odds data, data feeds, website and
        any other services provided at https://prostattrends.com (the “Service”) operated by ProStatTrends, LLC.
        <br />
        <br />
        Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose
        information that results from your use of our web pages. Please read it here https://prostattrends.com/privacy.
        <br />
        <br />
        Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have
        read and understood the Agreements, and agree to be bound by them.
        <br />
        <br />
        You may not use the Service for any revenue-generating endeavor or commercial enterprise.
        <br />
        <br />
        If you do not agree with (or cannot comply with) these Agreements, then you may not use the Service, but please
        let us know by emailing support@prostattrends.com so we can try to find a solution. These Agreements apply to
        all visitors, users and others who wish to access or use the Service.
      </>
    ),
  },
  {
    title: 'COMMUNICATIONS',
    content: (
      <>
        By creating an account on our Service, you agree to subscribe to newsletters, marketing or promotional materials
        and other information we may send. However, you may opt out of receiving any, or all, of these communications
        from us by following the unsubscribe link or by emailing support@prostattrends.com.
      </>
    ),
  },
  {
    title: 'PURCHASES',
    content: (
      <>
        If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to
        supply certain information relevant to your Purchase including, without limitation, your credit card number, the
        expiration date of your credit card, your billing address, and your shipping information.
        <br />
        <br />
        You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment
        method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and
        complete.
        <br />
        <br />
        We may employ the use of third party services for the purpose of facilitating payment and the completion of
        Purchases. By submitting your information, you grant us the right to provide the information to these third
        parties subject to our Privacy Policy.
        <br />
        <br />
        We reserve the right to refuse or cancel your order at any time for reasons including but not limited to product
        or service availability, errors in the description or price of the product or service, errors in your order or
        other reasons.
        <br />
        <br />
        We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is
        suspected.
      </>
    ),
  },
  {
    title: 'CONTESTS, SWEEPSTAKES AND PROMOTIONS',
    content: (
      <>
        Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through Service may be
        governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please
        review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these
        Terms of Service, Promotion rules will apply.
      </>
    ),
  },
  {
    title: 'SUBSCRIPTIONS',
    content: (
      <>
        Some parts of the Service are billed on a subscription basis (“Subscription(s)”). You will be billed in advance
        on a recurring and periodic basis (“Billing Cycle”). Billing cycles are set either on a monthly or annual basis,
        depending on the type of subscription plan you select when purchasing a Subscription.
        <br />
        <br />
        At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions
        unless you cancel it or we cancel it. You may cancel your Subscription renewal either through your online
        account management page or by contacting our customer support team at support@prostattrends.com.
        <br />
        <br />A valid payment method, including credit card, Apple Pay, Google Pay or Cash App, is required to process
        the payment for your subscription. You shall provide us with accurate and complete billing information including
        full name, address, state, zip code, and valid payment method information. By submitting such payment
        information, you automatically authorize us to charge all Subscription fees incurred through your account to
        such payment instruments. Should automatic billing fail to occur for any reason, we will issue an electronic
        invoice indicating that you must proceed manually, within a certain deadline date, with the full payment
        corresponding to the billing period as indicated on the invoice.
      </>
    ),
  },
  {
    title: 'FREE TRIAL',
    content: (
      <>
        We may, in our sole discretion, offer a Subscription with a free trial for a limited period of time (“Free
        Trial”).
        <br />
        <br />
        You may be required to enter your billing information in order to sign up for a Free Trial. If you do enter your
        billing information when signing up for a Free Trial, you will not be charged until the Free Trial has expired.
        On the last day of the Free Trial period, unless you cancel your Subscription, you will be automatically charged
        the applicable Subscription fees for the type of Subscription you have selected.
        <br />
        <br />
        At any time and without notice, we reserve the right to (i) modify these Terms of Service of any Free Trial
        offer, or (ii) cancel any Free Trial offer. You may not sign up for multiple free trials using different
        identification information (names, email addresses, and identities).
      </>
    ),
  },
  {
    title: 'FEE CHANGES',
    content: (
      <>
        We in our sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription
        fee change will become effective at the end of the then-current Billing Cycle. We will provide you with
        reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your
        Subscription before such change becomes effective. Your continued use of the Service after the Subscription fee
        change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
      </>
    ),
  },
  {
    title: 'REFUNDS',
    content: <>Except when required by law, paid Subscription fees are non-refundable.</>,
  },
  {
    title: 'CONTENT',
    content: (
      <>
        Our Service allows you to post, link, store, share and otherwise make available certain information, text,
        graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through the
        Service, including its legality, reliability, and appropriateness.
        <br />
        <br />
        By posting Content on or through Service, you represent and warrant that: (i) the Content is yours (you own it)
        and/or you have the right to use it and the right to grant us the right and license provided in these Terms, and
        (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity
        rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to
        terminate the account of anyone found to be infringing our rights or the rights of any third party.
        <br />
        <br />
        You retain any and all of your rights to any Content you submit, post or display on or through Service and you
        are responsible for protecting those rights. We take no responsibility and assume no liability for Content you
        or any third party posts on or through Service. However, by posting Content using Service you grant us the right
        and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and
        through the Service. You agree that this license includes the right for us to make your Content available to
        other users of the Service, who may also use your Content subject to these Terms.
        <br />
        <br />
        We have the right but not the obligation to monitor and edit all Content provided by users. In addition, all
        Content other than user-generated Content found on or through this Service is the property of ProStatTrends,
        LLC. or used with permission.
        <br />
        <br />
        You may not distribute, modify, transmit, reuse, download, repost, copy, or use such Content, whether in whole
        or in part, for commercial purposes or for personal gain, without express advance written permission from us.
      </>
    ),
  },
  {
    title: 'PROHIBITED USES',
    content: (
      <>
        You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use
        Service:
        <br />
        <br />
        (a) In any way that violates any applicable national or international law or regulation.
        <br />
        <br />
        (b) For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them
        to inappropriate content or otherwise.
        <br />
        <br />
        (c) To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”,
        “chain letter,” “spam,” or any other similar solicitation.
        <br />
        <br />
        (d) To impersonate or attempt to impersonate us, our employees, another user, or any other person or entity.
        <br />
        <br />
        (e) In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or
        harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
        <br />
        <br />
        (f) To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as
        determined by us, may harm or offend us or users of Service or expose them to liability. Additionally, you agree
        not to:
        <br />
        <br />
        (a) Use Service in any manner that could disable, overburden, damage, or impair the Service or interfere with
        any other party’s use of Service, including their ability to engage in real time activities through Service.
        <br />
        <br />
        (b) Use any robot, spider, or other automatic device, process, or means to access the Service for any purpose,
        including monitoring or copying any of the material on the Service.
        <br />
        <br />
        (c) Use any manual process to monitor or copy any of the material on the Service or for any other unauthorized
        purpose without our prior written consent.
        <br />
        <br />
        (d) Use any device, software, or routine that interferes with the proper working of the Service.
        <br />
        <br />
        (e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or
        technologically harmful.
        <br />
        <br />
        (f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service, the
        server on which the Service is stored, or any server, computer, or database connected to the Service.
        <br />
        <br />
        (g) Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
        <br />
        <br />
        (h) Take any action that may harm us or damage the Service.
        <br />
        <br />
        (i) Otherwise attempt to interfere with the proper working of the Service.
        <br />
        <br />
        (j) Use the Service as part of any effort to compete with us or otherwise use the Service and/or the Content for
        any revenue-generating endeavor or commercial enterprise.
      </>
    ),
  },
  {
    title: 'ANALYTICS',
    content: (
      <>
        We may use third-party Service Providers to monitor and analyze the use of our Service.
        <br />
        <br />
        Google Analytics
        <br />
        <br />
        Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google
        uses the data collected to track and monitor the use of our Service. This data is shared with other Google
        services. Google may use the collected data to contextualize and personalize the ads of its own advertising
        network. For more information on the privacy practices of Google, please visit the Google Privacy Terms web
        page: https://policies.google.com/privacy.
        <br />
        <br />
        We also encourage you to review the Google's policy for safeguarding your data:
        https://support.google.com/analytics/answer/6004245.
      </>
    ),
  },
  {
    title: 'NO USE BY MINORS',
    content: (
      <>
        Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or
        using the Service, you warrant and represent that you are at least eighteen (18) years of age and with the full
        authority, right, and capacity to enter into the Agreements and abide by the Terms. If you are not at least
        eighteen (18) years old, you are prohibited from both accessing or using the Service.
      </>
    ),
  },
  {
    title: 'ACCOUNTS',
    content: (
      <>
        When you create an account with us, you guarantee that you are above the age of 18, and that the information you
        provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may
        result in the immediate termination of your account on the Service.
        <br />
        <br />
        You are responsible for maintaining the confidentiality of your account and password, including but not limited
        to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all
        activities or actions that occur under your account and/or password, whether your password is with our Service
        or a third-party service. You must notify us immediately upon becoming aware of any breach of security or
        unauthorized use of your account.
        <br />
        <br />
        You are prohibited from creating or using multiple accounts. Each user may only create one account, and any
        attempt to create additional accounts may result in the termination of all associated accounts.
        <br />
        <br />
        Sharing of accounts is strictly prohibited. Each account is for individual use only, and sharing your account
        with others may result in the termination of your account.
        <br />
        <br />
        We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole
        discretion.
      </>
    ),
  },
  {
    title: 'NO PERSONALIZED ADVICE',
    content: (
      <>
        We want to help you make money. However, we are not in the business of rendering personalized betting advice. We
        can't know all the relevant facts about you and your individual needs, and we cannot claim or represent that any
        particular bet is suitable for you. Accordingly, you agree that any recommendation or action taken does not
        constitute a recommendation that a particular bet is suitable for you. We may partner with third parties in
        order to make betting our recommendations easier for you. We will not, and cannot, bet on your behalf at any
        sportsbook. Only you can decide whether or not a bet is right for you and you agree to be liable for any bet you
        initiate with your sportsbook accounts using tools that we or our partners provide.
      </>
    ),
  },
  {
    title: 'YOU BEAR RESPONSIBILITY FOR YOUR FINANCIAL AND INVESTMENT DECISIONS',
    content: (
      <>
        One of the principal tenets here at ProStatTrends is that the best person to handle your bets is you. By using
        our Service, you agree that you bear responsibility for your own bets. You also agree that ProStatTrends, LLC,
        its directors, its employees, and its agents will not be liable for any bets made or action taken by you and
        others based on news, information, opinion, or any other material published through our service.
      </>
    ),
  },
  {
    title: 'INTELLECTUAL PROPERTY',
    content: (
      <>
        The Service and its original content (excluding Content provided by users), features and functionality are and
        will remain the exclusive property of ProStatTrends, LLC. and its licensors. The Service is protected by
        copyright, trademark, and other laws of the Canada and foreign countries. Our trademarks and trade dress may not
        be used in connection with any product or service without our prior written consent.
      </>
    ),
  },
  {
    title: 'CONFIDENTIALITY',
    content: (
      <>
        From time to time, we may disclose or make available to you, our proprietary information which is not generally
        known to the public that we use, developed or have obtained (“Confidential Information”). Without limiting the
        foregoing: (a) if we discuss information about our Services with you, such discussions are considered
        ProStatTrends's Confidential Information; (b) if you learn it behind our paywall, it is considered
        ProStatTrends's Confidential Information; and (c) if your Subscription includes delivery of odds data or a data
        feed (“Service Data”), such Service Data shall be considered Props Daily's Confidential Information.
        Confidential Information does not include any information that: (w) is or becomes generally available to the
        public other than as a result of Receiving Party's breach of this section; (x) is or becomes available to you on
        a non-confidential basis from a third-party source, provided that such third party is not and was not prohibited
        from disclosing such Confidential Information; (y) was in your possession prior to disclosure hereunder; or (z)
        was or is independently developed by you without using any Confidential Information. You must: (a) protect and
        safeguard the confidentiality of our Confidential Information with at least the same degree of care as you would
        protect your own Confidential Information, but in no event with less than a commercially reasonable degree of
        care; (b) not our Confidential Information, or permit it to be accessed or used, for any purpose other than to
        exercise your rights or perform your obligations under these Terms; and (c) not disclose any such Confidential
        Information to any other person or entity. If you are required by applicable law or legal process to disclose
        any Confidential Information, you must, prior to making such disclosure, use commercially reasonable efforts to
        notify us of such requirements to afford us the opportunity to seek, at our sole cost and expense, a protective
        order or other remedy. You represent and warrant that you: (a) will not publish the Service or any Service Data
        received through the Service; (b) will not allow any person to access the Services or Service Data received; and
        (c) will use the Services and Service Data consistent with all applicable laws. You represent and warrant that
        you have obtained all requisite approvals, registrations, permits, authorizations, or licenses necessary to
        provide your products and services. Notwithstanding anything herein or in any other agreement between you and us
        to the contrary, we may disclose, to any gaming authority or other regulatory body, without any violation of
        these Terms or any other agreement between you and us, a copy of these Terms and any other agreement.
      </>
    ),
  },
  {
    title: 'ERROR REPORTING AND FEEDBACK',
    content: (
      <>
        You may provide us either directly at support@prostattrends.com (or via third party sites and tools) with
        information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other
        matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire
        or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) we may
        have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or
        proprietary information from you or any third party; and (iv) we are not under any obligation of confidentiality
        with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to
        applicable mandatory laws, you grant us and our affiliates an exclusive, transferable, irrevocable,
        free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative
        works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
      </>
    ),
  },
  {
    title: 'LINKS TO OTHER WEB SITES',
    content: (
      <>
        Our Service may contain links to third party websites or services that are not owned or controlled by us.
        <br />
        <br />
        We have no control over, and assume no responsibility for the content, privacy policies, or practices of any
        third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their
        websites.
        <br />
        <br />
        You acknowledge and agree that ProStatTrends, LLC. shall not be responsible or liable, directly or indirectly,
        for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such
        content, goods or services available on or through any such third party websites or services. We strongly advise
        you to read the terms of service and privacy policies of any third party websites or services that you visit.
      </>
    ),
  },
  {
    title: 'DISCLAIMER OF WARRANTY',
    content: (
      <>
        These services are provided by us on an “as is” and “as available” basis. We make no representations or
        warranties of any kind, express or implied, as to the operation of the services, service data, or the
        information, content or materials included therein. You expressly agree that your use of these services, service
        data, information, content, and any materials obtained from us is at your sole risk. Neither we nor any person
        associated with us makes any warranty or representation with respect to the completeness, security, reliability,
        quality, accuracy, or availability of the services. Without limiting the foregoing, neither we nor anyone
        associated with us represents or warrants that the services, the service data, the content, or any materials
        obtained through the services will be accurate, reliable, error-free, or uninterrupted, that defects will be
        corrected, that the services or the server that makes it available are free of viruses or other harmful
        components or that the services, service data, the content, or any materials obtained through the services will
        otherwise meet your needs or expectations. We hereby disclaim all warranties of any kind, whether express or
        implied, statutory, or otherwise, including but not limited to any warranties of merchantability,
        non-infringement, and fitness for a particular purpose. The foregoing does not affect any warranties which
        cannot be excluded or limited under applicable law.
      </>
    ),
  },
  {
    title: 'LIMITATION OF LIABILITY',
    content: (
      <>
        Except as prohibited by law, you will hold us and our officers, directors, employees, and agents harmless for
        any indirect, punitive, special, incidental, or consequential damage, however it arises (including attorneys'
        fees and all related costs and expenses of litigation and arbitration, or at trial or on appeal, if any, whether
        or not litigation or arbitration is instituted), and whether in an action of contract, negligence, or other
        tortious action, or arising out of or in connection with this agreement, including without limitation any claim
        for personal injury or property damage, arising from this agreement and any violation by you of any federal,
        state, or local laws, statutes, rules, or regulations, even if we have been previously advised of the
        possibility of such damage. Except as prohibited by law, if there is liability found on our part, it will be
        limited to the amount paid for the service data and/or services, and under no circumstances will there be
        consequential or punitive damages. Some states do not allow the exclusion or limitation of punitive, incidental,
        or consequential damages, so the prior limitation or exclusion may not apply to you.
      </>
    ),
  },
  {
    title: 'TERMINATION',
    content: (
      <>
        We may terminate or suspend your account and bar access to the Service immediately, without prior notice or
        liability, under our sole discretion, for any reason whatsoever and without limitation, including but not
        limited to a breach of these Terms. If you wish to terminate your account, you may simply discontinue using the
        Service. All provisions of these Terms which by their nature should survive termination shall survive
        termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and
        limitations of liability.
      </>
    ),
  },
  {
    title: 'CHANGES TO SERVICE',
    content: (
      <>
        We reserve the right to withdraw or amend our Service, and any service or material we provide via the Service,
        in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is
        unavailable at any time or for any period. From time to time, we may restrict access to some parts of the
        Service, or the entire Service, to users, including registered users.
      </>
    ),
  },
  {
    title: 'AMENDMENTS TO TERMS',
    content: (
      <>
        We may amend these Terms at any time by posting the amended terms on this site. It is your responsibility to
        review these Terms periodically. You are expected to check this page frequently so you are aware of any changes,
        as they are binding on you. By continuing to access or use our Service after any revisions become effective, you
        agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to
        use the Service.
      </>
    ),
  },
  {
    title: 'WAIVER AND SEVERABILITY',
    content: (
      <>
        No waiver by us of any term or condition set forth in these Terms shall be deemed a further or continuing waiver
        of such term or condition or a waiver of any other term or condition, and any failure by us to assert a right or
        provision under these Terms shall not constitute a waiver of such right or provision. If any provision of these
        Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for
        any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining
        provisions of these Terms will continue in full force and effect.
      </>
    ),
  },
  {
    title: 'ACKNOWLEDGEMENT',
    content: (
      <>
        By using the service or other services provided by us, you acknowledge that you have read these terms of service
        and agree to be bound by them.
      </>
    ),
  },
  {
    title: 'CONTACT US',
    content: (
      <>Please send your feedback, comments, requests for technical support: By email: support@prostattrends.com.</>
    ),
  },
];

export default function Terms() {
  return (
    <div className="page__container">
      <ScrollToTop />
      <div className="page__title">Terms & Conditions</div>
      <div className="page__sub-title">Effective date: 08/01/2024</div>
      <div className="page__line-items">
        {lineItems.map((item, index) => (
          <div className="page__line-item" key={item.title}>
            <div className="page__line-item-title">
              {index + 1}. {item.title}
            </div>
            <div className="page__line-item-content">{item.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
