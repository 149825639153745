import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Flex } from 'antd';

import './Footer.scss';

function Footer() {
  return (
    <div className="footer__container">
      <div className="footer__body">
        <div>
          <div className="footer__logo">
            <img src="/logos/rop.png" alt="logo" />
          </div>
          &reg; {dayjs().format('YYYY')} ProStatTrends, LLC. All Rights Reserved.
          <br />
          <br />
          The content of this website is intended to be used for entertainment purposes only. Please be aware of and
          respect the laws regarding sports betting for your jurisdiction.
          <br />
          <br />
          We are not affiliated, associated, authorized, endorsed by, or in any way officially connected with the NFL,
          MLB, NBA, NHL, or any other league or team. Use of team names, logos, or images do not imply any affiliation
          with or endorsement by them.
        </div>
        <Flex align="center" justify="center" gap={16} style={{ marginTop: 16 }}>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Use</Link>
        </Flex>
      </div>
    </div>
  );
}

export default Footer;
