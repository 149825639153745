import React from 'react';
import { SignUp } from '@clerk/clerk-react';
import { Flex, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './Login.scss';
function SignUpWrapper() {
  const navigate = useNavigate();
  return (
    <Flex justify="center" vertical={true} align="center" style={{ paddingTop: 16 }}>
      <Button onClick={() => navigate(-1)} type="primary" shape="round" icon={<ArrowLeftOutlined />}>
        Go back
      </Button>
      <SignUp signInUrl="/signin" />
    </Flex>
  );
}

export default SignUpWrapper;
