import React from 'react';
import { NbaStatsBody, Sport } from '../../types';
import { useGetMlbPlayerStatsQuery } from '../../redux/queries/mlbApi';
import { useGetNbaPlayerStatsQuery } from '../../redux/queries/nbaApi';
import { useGetNflPlayerStatsQuery } from '../../redux/queries/nflApi';
import { useGetNhlPlayerStatsQuery } from '../../redux/queries/nhlApi';
import MarketTableStats from '../Betting/MarketTableStats';

function FavoritePlayerStats({ body }: { body: NbaStatsBody }) {
  const { data: nbaStats } = useGetNbaPlayerStatsQuery(
    { playerId: body.playerId as string, body },
    { skip: body.sport !== Sport.nba }
  );
  const { data: mlbStats } = useGetMlbPlayerStatsQuery(
    { playerId: body.playerId as string, body },
    { skip: body.sport !== Sport.mlb }
  );
  const { data: nflStats } = useGetNflPlayerStatsQuery(
    { playerId: body.playerId as string, body },
    { skip: body.sport !== Sport.nfl }
  );
  const { data: nhlStats } = useGetNhlPlayerStatsQuery(
    { playerId: body.playerId as string, body },
    { skip: body.sport !== Sport.nhl }
  );

  const stats =
    body.sport === Sport.nba
      ? nbaStats?.stats
      : body.sport === Sport.mlb
        ? mlbStats?.stats
        : body.sport === Sport.nfl
          ? nflStats?.stats
          : body.sport === Sport.nhl
            ? nhlStats?.stats
            : null;

  if (!stats) return null;

  return <MarketTableStats body={body} stats={stats} compact={true} showOverUnder={false} />;
}

export default FavoritePlayerStats;
