import React from 'react';
import { Flex, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import cn from 'classnames';
import { MlbTeam, OddsEvent } from 'src/types';
import { useGetMlbTeamsQuery } from 'src/redux/queries/mlbApi';

import './BettingEvent.scss';

export const AmericanLeague = {
  mlbLogo1: '../../../logos/mlb_american_league_logo.svg',
  espnLogo1: '../../../logos/mlb_american_league_logo.svg',
  teamAbv: 'AL',
  teamName: 'American League',
  teamCity: 'American',
  wins: '0',
  loss: '0',
};

export const NationalLeague = {
  mlbLogo1: '../../../logos/mlb_national_league_logo.png',
  espnLogo1: '../../../logos/mlb_national_league_logo.png',
  teamAbv: 'NL',
  teamName: 'National League',
  teamCity: 'National',
  wins: '0',
  loss: '0',
};

const getTeam = (teams: MlbTeam[] | undefined, teamName: string): MlbTeam | null => {
  return (
    (teams || []).find((team: MlbTeam) => {
      return `${team.teamCity} ${team.teamName}` === teamName;
    }) || null
  );
};
function MLBEvent({ event, handleNavigate }: { event: OddsEvent; handleNavigate: (eventId: string) => void }) {
  const { data: teams } = useGetMlbTeamsQuery();

  let homeTeam: Partial<MlbTeam> | null = getTeam(teams, event.home_team);
  let awayTeam: Partial<MlbTeam> | null = getTeam(teams, event.away_team);

  if (event.home_team === 'American League') {
    homeTeam = AmericanLeague;
    awayTeam = NationalLeague;
  } else if (event.home_team === 'National League') {
    awayTeam = AmericanLeague;
    homeTeam = NationalLeague;
  }

  const Team = ({ team, isHome }: { team: MlbTeam; isHome: boolean }) => {
    const logoCss = cn('betting__event-logo-avatar', {
      'betting__event-logo-avatar--home': isHome,
    });

    return (
      <div>
        <Flex align="flex-start">
          <div className="betting__event-logo">
            <div className={logoCss}>
              <img src={team?.mlbLogo1} alt={team?.teamAbv} />
            </div>
            <div className="betting__event-team-wrapper">
              <div className="betting__event-team-name">
                {team?.teamName} <span className="city">{team?.teamCity}</span>
              </div>
              <div className="betting__event-win-loss">
                {team?.wins} - {team?.loss}
              </div>
            </div>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <div className="betting__event-wrapper">
      <div className="betting__event betting__event--secondary">
        <Flex align="center" justify="space-between">
          <Flex align="flex-start" vertical={true} justify="space-between">
            <Team team={homeTeam as MlbTeam} isHome />
            <Team team={awayTeam as MlbTeam} isHome={false} />
          </Flex>
          <Flex gap={8} vertical={true} align="center" justify="space-between">
            <div className="betting__event-start-time">{dayjs(event.commence_time).format('h:mm A')}</div>
            <div>
              <Button
                onClick={() => handleNavigate(event.id)}
                icon={<ArrowRightOutlined />}
                shape="round"
                type="primary"
                iconPosition="end"
              >
                Props
              </Button>
            </div>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}

export default MLBEvent;
