import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { AntDProvider } from './providers/AntDProvider';
import { ClerkProviderWrapper } from './providers/ClerkProvider';
import { ReduxStoreProvider } from './providers/ReduxStoreProvider';
import { BrowserRouter } from 'react-router-dom';
import AskAQuestion from './components/AskAQuestion/AskAQuestion';
import SocketBanner from './socket/SocketBanner';

import './index.css';

const isDevelopment = process.env.NODE_ENV === 'development';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ReduxStoreProvider>
      <ClerkProviderWrapper>
        <AntDProvider>
          <>
            <div
              style={{
                fontSize: 12,
                padding: 5,
                color: '#fff',
                textAlign: 'center',
                backgroundColor: 'darkgreen',
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
            >
              Beta version, Data will be deleted when we go live
            </div>
          </>
          <BrowserRouter>
            <SocketBanner />
            <div id="app">
              <App />
            </div>
            <AskAQuestion />
          </BrowserRouter>
        </AntDProvider>
      </ClerkProviderWrapper>
    </ReduxStoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (isDevelopment) reportWebVitals(console.log);
