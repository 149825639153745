import React from 'react';
import './AppVersionMismatch.scss';
import { Button, Flex } from 'antd';

function AppVersionMismatch() {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={'app-version-mismatch__container'}>
      <Flex gap={8} align="center" justify="center">
        <div>We have released an update</div>
        <div>
          <Button type="primary" shape="round" size="small" onClick={reloadPage}>
            Refresh
          </Button>
        </div>
      </Flex>
    </div>
  );
}

export default AppVersionMismatch;
