import React from 'react';
import { Flex } from 'antd';
import cn from 'classnames';
import { BOOKMAKER_LOGOS } from 'src/constants';
import './BookmakerDisplay.scss';

function BookmakerDisplay({
  bookmaker,
  price,
  children,
  compact = false,
}: {
  bookmaker: string;
  price: number;
  children: React.ReactNode;
  compact?: boolean;
}) {
  const bookmakerDisplayContainerCss = cn('bookmaker-display__container', {
    'bookmaker-display__container--compact': compact,
  });
  return (
    <Flex vertical={!compact} align="center" className={bookmakerDisplayContainerCss}>
      <Flex align="center" gap={compact ? 0 : 8} justify="center" className="bookmaker-display__logo-price">
        <div className="bookmaker-display__logo">
          <img src={`../../../logos/bookmakers/${BOOKMAKER_LOGOS[bookmaker]}`} alt={bookmaker} />
        </div>
        <div className="bookmaker-display__price">{price}</div>
      </Flex>
      <Flex align="center" justify="center" className="bookmaker-display__rating">
        <div>{children}</div>
      </Flex>
    </Flex>
  );
}

export default BookmakerDisplay;
