import React from 'react';
import { Avatar, Flex, Button, message, Spin } from 'antd';
import cn from 'classnames';
import { HeartOutlined } from '@ant-design/icons';
import './Favorites.scss';
import FavoriteProp from './Favorite';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_QUERY } from '../../constants';
import { useGetFavoritesQuery, useDeleteAllFavoritesMutation } from 'src/redux/queries/favoritesApi';

const Favorites = ({ handleNavigation }: { handleNavigation: (type: string) => void }) => {
  const isTabletOrMobile = useMediaQuery({ query: MOBILE_QUERY });

  const { data: favoritesData = [], isLoading: isLoadingFavorites } = useGetFavoritesQuery();
  const [deleteAllFavorites, { isLoading: isDeleting }] = useDeleteAllFavoritesMutation();

  const containerClass = cn('favorites__container', {
    'favorites__container--mobile': isTabletOrMobile,
  });

  const favoritesListClass = cn('favorites__list', {
    'favorites__list--mobile': isTabletOrMobile,
  });

  const handleClearAll = async () => {
    try {
      await deleteAllFavorites();
      message.success('Cleared all favorites');
    } catch (error) {
      message.error('We ran into a problem.');
      console.log(error);
    }
  };

  return (
    <div className={containerClass}>
      <Flex gap={8} align="center" justify={'center'} className="favorites__header">
        <div className="favorites_a_icon">
          <Avatar style={{ backgroundColor: '#ff0000' }} size={20} icon={<HeartOutlined />} />
        </div>
        <div className="favorites__title">
          <div>Favorites Trends</div>
          <div className="favorites__title--subtitle">
            Save your favorite trends by clicking the "add to favorites" button
          </div>
        </div>
      </Flex>
      {isLoadingFavorites ? (
        <Spin />
      ) : (
        <div className={favoritesListClass}>
          <Flex justify="flex-end">
            <Button loading={isDeleting} size="small" type="primary" shape="round" onClick={handleClearAll}>
              Clear All
            </Button>
          </Flex>
          <Flex vertical={isTabletOrMobile} gap={16} style={{ flexWrap: isTabletOrMobile ? 'nowrap' : 'wrap' }}>
            {favoritesData.map(favorite => (
              <FavoriteProp key={favorite.id} favorite={favorite} handleNavigation={handleNavigation} />
            ))}
          </Flex>
        </div>
      )}
    </div>
  );
};

export default Favorites;
