import { NbaStatsBody } from './prop-request-body';

export enum NflStatsMetric {
  RUSH_YDS = 'Rushing>rushYds',
  RUSH_CARRIES = 'Rushing>carries',
  RUSH_TD = 'Rushing>rushTD',
  RUSH_AVG = 'Rushing>rushAvg',
  RUSH_LONG_RUSH = 'Rushing>longRush',

  PASSING_SACKED = 'Passing>sacked',
  PASSING_ATTEMPTS = 'Passing>passAttempts',
  PASSING_AVG = 'Passing>passAvg',
  PASSING_TD = 'Passing>passTD',
  PASSING_YDS = 'Passing>passYds',
  PASSING_INT = 'Passing>int',
  PASSING_COMP = 'Passing>passCompletions',

  FANTASY_STANDARD = 'fantasyPointsDefault>standard',
  FANTASY_PPR = 'fantasyPointsDefault>PPR',
  FANTASY_HALF_PPR = 'fantasyPointsDefault>halfPPR',

  RECEIVING_RECS = 'Receiving>receptions',
  RECEIVING_YDS = 'Receiving>recYds',
  RECEIVING_AVG = 'Receiving>recAvg',
  RECEIVING_TD = 'Receiving>recTD',
  RECEIVING_LONG_REC = 'Receiving>longRec',
  RECEIVING_TARGETS = 'Receiving>targets',

  KICKING_FG_LONG = 'Kicking>fgLong',
  KICKING_FG_MADE = 'Kicking>fgMade',
  KICKING_FG_ATTEMPTS = 'Kicking>fgAttempts',
  KICKING_FG_PCT = 'Kicking>fgPct',
  KICKING_XP_MADE = 'Kicking>xpMade',
  KICKING_XP_ATTEMPTS = 'Kicking>xpAttempts',
  KICKING_PTS = 'Kicking>kickingPts',

  DEFENSE_TOTAL_TACKLES = 'Defense>totalTackles',
  DEFENSE_DEF_TD = 'Defense>defTD',
  DEFENSE_SOLO_TACKLES = 'Defense>soloTackles',
  DEFENSE_TFL = 'Defense>tfl',
  DEFENSE_QB_HITS = 'Defense>qbHits',
  DEFENSE_DEF_INTERCEPTIONS = 'Defense>defensiveInterceptions',
  DEFENSE_SACKS = 'Defense>sacks',
  DEFENSE_PASS_DEFLECTIONS = 'Defense>passDeflections',

  FUMBLES_LOST = 'Defense>fumblesLost',
  FUMBLES = 'Defense>fumbles',
  FUMBLES_RECOVERED = 'Defense>fumblesRecovered',
}

export type NflPlayerStats = {
  teamAbv: string; // 'WSH';
  longName: string; // 'Terry McLaurin';
  playerID: string; // '3121422';
  team: string; // 'WSH';
  teamID: string; // '32';
  gameID: string; // '20231022_WSH@NYG';
  fantasyPoints: string; // '15.0';
  Passing?: {
    qbr: string; // '65.4';
    rtg: string; // '86.1';
    sacked: string; // '1-4';
    passAttempts: string; // '39';
    passAvg: string; // '4.8';
    passTD: string; // '1';
    passYds: string; // '186';
    int: string; // '0';
    passCompletions: string; // '26';
  };
  Receiving?: {
    receptions: string; // '6';
    recTD: string; // '0';
    longRec: string; // '31';
    targets: string; // '9';
    recYds: string; // '90';
    recAvg: string; // '15.0';
  };
  Defense?: {
    fumblesLost: string; // '0';
    defensiveInterceptions: string; // '0';
    fumbles: string; // '1';
    fumblesRecovered: string; // '0';
    totalTackles: string; // '13';
    defTD: string; // '0';
    soloTackles: string; // '9';
    tfl: string; // '0';
    qbHits: string; // '0';
    sacks: string; // '0';
    passDeflections: string; // '0';
  };
  Rushing?: {
    rushAvg: string; // '6.0';
    rushYds: string; // '72';
    carries: string; // '12';
    longRush: string; // '18';
    rushTD: string; // '2';
  };
  Kicking?: {
    fgLong: string; // '27';
    fgMade: string; // '1';
    fgAttempts: string; // '2';
    xpMade: string; // '3';
    fgPct: string; // '50.0';
    kickingPts: string; // '6';
    xpAttempts: string; // '3';
  };
  fantasyPointsDefault: {
    standard: string; // '9.0';
    PPR: string; // '15.0';
    halfPPR: string; // '12.0';
  };
};

export type NflGameStat = Record<string, NflPlayerStats>;

export type NflGameStats = {
  statusCode: number;
  body: NflGameStat;
};

export type NflStatTypes = 'away' | 'home' | 'all' | 'vs';

export type NflStatType = Record<
  NflStatTypes,
  {
    percentage: number;
    stats: {
      game: string;
      gameDate: string;
      total: number;
      thresholdMet: boolean;
      metaData: NflPlayerStats;
    }[];
  }
>;

export type NflPlayerStatResult = {
  stats: NflStatType;
  criteria: NbaStatsBody;
  rating: number | null;
};
