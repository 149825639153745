import React from 'react';
import { Tag } from 'antd';
import { StarFilled, DislikeFilled, CaretUpFilled } from '@ant-design/icons';
const isDevelopment = process.env.NODE_ENV === 'development';

export function Rating({ rating }: { rating: number }) {
  if (isDevelopment) {
    return <>{rating && rating.toFixed(1)}</>;
  }

  if (rating > 80) {
    return (
      <Tag style={{ margin: 0 }} color={'#50853a'} icon={<StarFilled />}>
        Great value
      </Tag>
    );
  }

  if (rating >= 55) {
    return (
      <Tag style={{ margin: 0 }} color={'#0f50d4'} icon={<CaretUpFilled />}>
        Fair value
      </Tag>
    );
  }

  if (rating < 55) {
    return (
      <Tag style={{ margin: 0 }} color={'#cd201f'} icon={<DislikeFilled />}>
        Stay away
      </Tag>
    );
  }

  return null;
}
